<template>
  <div class="inner-pages homepage-4 agents list hp-6 full hd-white">
    <form-wizard
      @on-loading="removeDiv"
      @on-change="removeDiv"
      @on-complete="submit"
      finishButtonText="Submit Property"
      :title="''"
      :subtitle="''"
      :color="'#548f4d'"
      :next-button-text="adjustmentForTerms"
      :start-index="startIndex"
      class="formWizard"
      ref="formWizard"
    >
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left d-none">
          <wizard-button
            @click.native="props.prevTab()"
            ref="prevButton"
            v-if="props.activeTabIndex > 0"
            :style="props.fillButtonStyle"
            >Previous</wizard-button
          >
        </div>
        <div class="d-none wizard-footer-right">
          <wizard-button
            ref="nextButton"
            v-if="!props.isLastStep"
            @click.native="props.nextTab()"
            class="wizard-footer-right"
            :style="props.fillButtonStyle"
            >Next</wizard-button
          >

          <wizard-button
            v-else
            @click.native="alert('Done')"
            class="wizard-footer-right finish-button"
            :style="props.fillButtonStyle"
            >{{ props.isLastStep ? "Create" : "Next" }}</wizard-button
          >
        </div>
      </template>
      <tab-content :before-change="check3" title="Address">
        <div class="inner-pages maxw1600 m0a dashboard-bd">
          <!-- Wrapper -->
          <div id="wrapper" class="int_main_wraapper">
            <section class="user-page section-padding pt-5">
              <div class="container">
                <div class="row">
                  <div
                    class="
                      col-lg-12 col-md-12 col-xs-12
                      royal-add-property-area
                      section_100
                      pl-0
                      user-dash2
                    "
                  >
                    <div class="single-add-property res-pr-0">
                      <div class="property-form-group">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 mb-4">
                            <div
                              style="
                                border-radius: 8px;
                                background-color: #f7901d;
                              "
                              class="yellew-text res-yellew-text"
                            >
                              <div
                                style="
                                  padding-left: 10px;
                                  padding-top: 10px;
                                  padding-bottom: 10px;
                                "
                              >
                                <i
                                  class="fas fa-exclamation-triangle"
                                  style="
                                    display: inline;
                                    font-size: 48px;
                                    color: white;
                                    margin-top: 12px;
                                    float: left;
                                    text-align: left;
                                    padding-right: 16px;
                                  "
                                ></i>

                                <p
                                  style="
                                    color: white;
                                    padding: 0px;
                                    margin: 0px;
                                  "
                                >
                                  We treat your privacy seriously and will not
                                  share the full property address on the
                                  listing. Only the Town/City, County and the
                                  first half the postcode will be displayed.
                                  <br />
                                  You can share your address with an interested
                                  buyer through our messaging system for
                                  viewings.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="addressType == 'manual' || switchTo"
                            class="col-lg-12 col-md-12"
                          >
                            <p>
                              <label for="address">House No.</label>
                              <input
                                @click="inFocus(false)"
                                :class="
                                  $v.houseNo.$dirty && !$v.houseNo.required
                                    ? 'error-border'
                                    : ''
                                "
                                v-model="houseNo"
                                type="text"
                                name="houseNo"
                                placeholder="House Number or Name"
                                id="houseNo"
                              />
                            </p>
                            <p
                              class="error mt-n4"
                              v-if="$v.houseNo.$dirty && !$v.houseNo.required"
                            >
                              This field is required!
                            </p>
                          </div>
                          <div
                            v-if="addressType == 'manual' || switchTo"
                            class="col-lg-6 col-md-12"
                          >
                            <p>
                              <label for="address">Street</label>
                              <input
                                @click="inFocus(false)"
                                :class="
                                  $v.street.$dirty && !$v.street.required
                                    ? 'error-border'
                                    : ''
                                "
                                v-model="street"
                                type="text"
                                name="street"
                                placeholder="Enter Your Street"
                                id="street"
                              />
                            </p>
                            <p
                              class="error mt-n4"
                              v-if="$v.street.$dirty && !$v.street.required"
                            >
                              This field is required!
                            </p>
                          </div>
                          <div
                            v-if="addressType == 'manual' || switchTo"
                            class="col-lg-6 col-md-12"
                          >
                            <p>
                              <label for="city">City</label>
                              <input
                                @click="inFocus(false)"
                                :class="
                                  $v.city.$dirty && !$v.city.required
                                    ? 'error-border'
                                    : ''
                                "
                                v-model="city"
                                type="text"
                                name="city"
                                placeholder="Enter Your City"
                                id="city"
                              />
                            </p>
                            <p
                              class="error mt-n4"
                              v-if="$v.city.$dirty && !$v.city.required"
                            >
                              This field is required!
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6 col-md-12">
                            <p>
                              <label for="state">Post Code</label>
                              <input
                                @click="inFocus(false)"
                                :class="
                                  $v.postCode.$dirty && !$v.postCode.required
                                    ? 'error-border'
                                    : ''
                                "
                                v-model="postCode"
                                type="text"
                                name="postCode"
                                placeholder="Enter Your Postcode"
                                id="postCode"
                              />
                            </p>
                            <p
                              class="error mt-n4"
                              v-if="$v.postCode.$dirty && !$v.postCode.required"
                            >
                              This field is required!
                            </p>
                            <select
                              @click="inFocus(false)"
                              v-if="addressType == 'lookup' && !switchTo"
                              v-model="lookupAddress"
                              :class="[
                                lookupError ? '' : 'mb-4',
                                'selectfiled arrows',
                              ]"
                            >
                              <option selected disabled value="1">
                                Select an address
                              </option>
                              <option
                                @click="inFocus(false)"
                                v-for="(address, index) in suggestions"
                                :key="index"
                                :value="address"
                              >
                                {{ address["line_1"] }}
                              </option>
                            </select>
                            <p class="error" v-if="lookupError">
                              Invalid Post Code
                            </p>
                          </div>
                          <div
                            v-if="addressType == 'manual' || switchTo"
                            class="col-lg-6 col-md-12"
                          >
                            <p>
                              <label for="county">County</label>
                              <input
                                @click="inFocus(false)"
                                v-model="county"
                                type="text"
                                name="county"
                                placeholder="Enter Your County"
                                id="county"
                              />
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            v-if="addressType !== 'lookup'"
                            class="col-lg-3 col-md-3 for-res"
                          >
                            <button
                              style="width: 190px"
                              @click="
                                addressType = 'lookup';
                                switchTo = false;
                              "
                              :class="[
                                'btn',
                                'btn-yellow',
                                'lookup',
                                'listing-button-color',
                              ]"
                            >
                              Look up address
                            </button>
                          </div>
                          <div
                            v-if="addressType !== 'manual'"
                            class="col-lg-3 col-md-3"
                          >
                            <button
                              style="color: white; width: 190px"
                              @click="addressType = 'manual'"
                              :class="[
                                'btn',
                                'res-display-sm-hide',
                                'btn-yellow responsive-line',
                                'listing-button-color',
                              ]"
                            >
                              Enter Manually
                            </button>
                            <button
                              style="color: white"
                              @click="addressType = 'manual'"
                              :class="[
                                'btn',
                                'res-hide-on-big',
                                'res-margin-l0',
                                'btn-yellow',
                                'listing-button-color',
                              ]"
                            >
                              Enter Manually
                            </button>
                          </div>
                        </div>
                        <div class="row"></div>
                        <div class="d-flex justify-content-center mt-4">
                          <div
                            style="
                              width: 100%;
                              height: 5vh;
                              background-color: #f6f6f6;
                            "
                          ></div>
                        </div>
                        <button
                          style="color: white"
                          v-if="
                            $refs.formWizard
                              ? $refs.formWizard.activeTabIndex > 0 &&
                                !$refs.formWizard.isLastStep
                              : false
                          "
                          class="
                            btn btn-yellow
                            listing-button-color
                            px-6
                            mt-3
                            pull-left
                          "
                          @click="back()"
                        >
                          Back
                        </button>
                        <button
                          style="color: white"
                          v-if="
                            $refs.formWizard
                              ? !$refs.formWizard.isLastStep
                              : true
                          "
                          class="
                            btn btn-yellow
                            listing-button-color
                            mt-3
                            px-6
                            float-right float-res-right
                          "
                          @click="next()"
                        >
                          <b-spinner small v-if="showSpinner"></b-spinner
                          >{{
                            !showSpinner
                              ? $refs.formWizard
                                ? $refs.formWizard.isLastStep
                                  ? "Create"
                                  : "Next"
                                : "Next"
                              : ""
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </tab-content>

      <tab-content :before-change="check4" title="About Property">
        <div v-if="drafted" class="inner-pages maxw1600 m0a dashboard-bd">
          <!-- Wrapper -->
          <div id="wrapper" class="int_main_wraapper">
            <section class="user-page section-padding pt-5">
              <div class="container">
                <div class="row">
                  <div
                    class="
                      col-lg-12 col-md-12 col-xs-12
                      royal-add-property-area
                      section_100
                      pl-0
                      user-dash2
                    "
                  >
                    <div class="single-add-property">
                      <div class="property-form-group">
                        <div
                          style="width: 100%"
                          class="row d-flex justify-content-center"
                        >
                          <div class="col-lg-8 col-md-12">
                            <p class="no-mb input-icon">
                              <label for="price"
                                >Price

                                <i
                                  v-b-popover.hover.right="
                                    'This is the price that your house will be listed for on Vencasa. You can amend the price at a later date. There are online calculators available for free to help with your valuation.'
                                  "
                                  class="fa fa-question-circle"
                                  aria-hidden="true"
                                ></i>
                                <b-popover
                                  target="popover-target-1"
                                  triggers="hover"
                                  placement="right"
                                >
                                </b-popover>
                              </label>

                              <input
                                @click="inFocus(false)"
                                :class="
                                  $v.price.$dirty && !$v.price.required
                                    ? 'error-border'
                                    : ''
                                "
                                :value="price"
                                @input="addCommas($event)"
                                @keydown="inputCheck($event)"
                                type="text"
                                name="price"
                                placeholder="Price"
                                id="price"
                                style="padding-left: 25px"
                              />
                              <i class="priceicon">£</i>
                            </p>
                            <p
                              class="error"
                              v-if="$v.price.$dirty && !$v.price.required"
                            >
                              This field is required!
                            </p>
                          </div>
                          <div class="col-lg-8 col-md-12 mt-3 res-move-mt">
                            <div class="dropdown faq-drop">
                              <label for="dropdowntype">Property Type</label>
                              <select
                                @change="setDescriptionTitle"
                                @click="inFocus(false)"
                                :class="
                                  $v.type.$dirty && !$v.type.required
                                    ? 'error-border'
                                    : ''
                                "
                                v-model="type"
                                class="
                                  color-black
                                  form-select
                                  pro-status
                                  dropdown-toggle
                                  arrows
                                "
                                aria-label="Default select example"
                              >
                                <option
                                  class="optionfiledfont"
                                  value="bungalow"
                                >
                                  Bungalow
                                </option>
                                <option
                                  class="optionfiledfont"
                                  value="terraced"
                                >
                                  Terraced
                                </option>
                                <option
                                  class="optionfiledfont"
                                  value="detached"
                                >
                                  Detached
                                </option>
                                <option
                                  class="optionfiledfont"
                                  value="semi-detached"
                                >
                                  Semi-Detached
                                </option>
                                <option class="optionfiledfont" value="flat">
                                  Flat
                                </option>
                                <option class="optionfiledfont" value="land">
                                  Land
                                </option>
                                <option class="optionfiledfont" value="none">
                                  None
                                </option>
                              </select>
                              <p
                                class="error"
                                v-if="$v.type.$dirty && !$v.type.required"
                              >
                                This field is required!
                              </p>
                            </div>
                          </div>
                          <div class="col-lg-8 col-md-12">
                            <div class="dropdown faq-drop no-mb">
                              <label for="age">Tenure</label>
                              <select
                                @change="setDescriptionTitle"
                                @click="inFocus(false)"
                                :class="
                                  $v.tenure.$dirty && !$v.tenure.required
                                    ? 'error-border'
                                    : ''
                                "
                                v-model="tenure"
                                class="
                                  color-black
                                  form-select
                                  pro-status
                                  dropdown-toggle
                                  arrows
                                "
                                aria-label="Default select example"
                              >
                                <option class="optionfiledfont" value="free">
                                  Freehold
                                </option>
                                <option class="optionfiledfont" value="lease">
                                  Leasehold
                                </option>
                              </select>
                              <p
                                class="error"
                                v-if="$v.tenure.$dirty && !$v.tenure.required"
                              >
                                This field is required!
                              </p>
                            </div>
                          </div>
                          <div class="col-lg-8 col-md-12 mt-3 res-move-mt">
                            <div class="dropdown faq-drop no-mb">
                              <label for="bed">Number of Bedrooms</label>
                              <select
                                @click="inFocus(false)"
                                @input="
                                  addToDescription(
                                    'bedroom',
                                    $event.target.value
                                  )
                                "
                                :value="bedroom.length"
                                class="
                                  color-black
                                  form-select
                                  pro-status
                                  dropdown-toggle
                                  arrows
                                "
                                aria-label="Default select example"
                              >
                                <option class="optionfiledfont" value="0">
                                  None
                                </option>
                                <option class="optionfiledfont" value="1">
                                  1
                                </option>
                                <option class="optionfiledfont" value="2">
                                  2
                                </option>
                                <option class="optionfiledfont" value="3">
                                  3
                                </option>
                                <option class="optionfiledfont" value="4">
                                  4
                                </option>
                                <option class="optionfiledfont" value="5">
                                  5
                                </option>
                                <option class="optionfiledfont" value="6">
                                  6
                                </option>
                                <option class="optionfiledfont" value="7">
                                  7
                                </option>
                                <option class="optionfiledfont" value="8">
                                  8
                                </option>
                                <option class="optionfiledfont" value="9">
                                  9
                                </option>
                                <option class="optionfiledfont" value="10">
                                  10
                                </option>
                                <option class="optionfiledfont" value="11">
                                  11
                                </option>
                                <option class="optionfiledfont" value="12">
                                  12+
                                </option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-8 col-md-12 mt-3 res-move-mt">
                            <div class="dropdown faq-drop no-mb last">
                              <label for="bath">Number of Bathrooms</label>
                              <select
                                @click="inFocus(false)"
                                @input="
                                  addToDescription(
                                    'bathroom',
                                    $event.target.value
                                  )
                                "
                                :value="bathroom.length"
                                class="
                                  color-black
                                  form-select
                                  pro-status
                                  dropdown-toggle
                                  arrows
                                "
                                aria-label="Default select example"
                              >
                                <option class="optionfiledfont" value="0">
                                  None
                                </option>
                                <option class="optionfiledfont" value="1">
                                  1
                                </option>
                                <option class="optionfiledfont" value="2">
                                  2
                                </option>
                                <option class="optionfiledfont" value="3">
                                  3
                                </option>
                                <option class="optionfiledfont" value="4">
                                  4
                                </option>
                                <option class="optionfiledfont" value="5">
                                  5
                                </option>
                                <option class="optionfiledfont" value="6">
                                  6
                                </option>
                                <option class="optionfiledfont" value="7">
                                  7
                                </option>
                                <option class="optionfiledfont" value="8">
                                  8
                                </option>
                                <option class="optionfiledfont" value="9">
                                  9
                                </option>
                                <option class="optionfiledfont" value="10">
                                  10
                                </option>
                                <option class="optionfiledfont" value="11">
                                  11
                                </option>
                                <option class="optionfiledfont" value="12">
                                  12+
                                </option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-8 col-md-12 mt-3 res-move-mt">
                            <div class="dropdown faq-drop no-mb">
                              <label for="bed">Number of Other Rooms</label>
                              <select
                                @click="inFocus(false)"
                                @input="
                                  addToDescription(
                                    'otherroom',
                                    $event.target.value
                                  )
                                "
                                :value="otherroom.length"
                                class="
                                  color-black
                                  form-select
                                  pro-status
                                  dropdown-toggle
                                  arrows
                                "
                                aria-label="Default select example"
                              >
                                <option class="optionfiledfont" value="0">
                                  None
                                </option>
                                <option class="optionfiledfont" value="1">
                                  1
                                </option>
                                <option class="optionfiledfont" value="2">
                                  2
                                </option>
                                <option class="optionfiledfont" value="3">
                                  3
                                </option>
                                <option class="optionfiledfont" value="4">
                                  4
                                </option>
                                <option class="optionfiledfont" value="5">
                                  5
                                </option>
                                <option class="optionfiledfont" value="6">
                                  6
                                </option>
                                <option class="optionfiledfont" value="7">
                                  7
                                </option>
                                <option class="optionfiledfont" value="8">
                                  8
                                </option>
                                <option class="optionfiledfont" value="9">
                                  9
                                </option>
                                <option class="optionfiledfont" value="10">
                                  10
                                </option>
                                <option class="optionfiledfont" value="11">
                                  11
                                </option>
                                <option class="optionfiledfont" value="12">
                                  12+
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-lg-8 col-md-12 mt-3 res-move-mt">
                            <div class="dropdown faq-drop no-mb">
                              <label for="age">Garage</label>
                              <select
                                @click="inFocus(false)"
                                v-model="garage"
                                class="
                                  color-black
                                  form-select
                                  pro-status
                                  dropdown-toggle
                                  arrows
                                "
                                aria-label="Default select example"
                              >
                                <option class="optionfiledfont" value="single">
                                  Single
                                </option>
                                <option class="optionfiledfont" value="double">
                                  Double
                                </option>
                                <option class="optionfiledfont" value="other">
                                  Other
                                </option>
                                <option class="optionfiledfont" value="none">
                                  None
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-lg-8 col-md-12 mt-3 res-move-mt">
                            <div class="dropdown faq-drop">
                              <label for="dropdowntype">Swimming Pool</label>
                              <select
                                @click="inFocus(false)"
                                v-model="swimmingPool"
                                class="
                                  color-black
                                  form-select
                                  pro-status
                                  dropdown-toggle
                                  arrows
                                "
                                aria-label="Default select example"
                              >
                                <option class="optionfiledfont" value="indoor">
                                  Indoor
                                </option>
                                <option class="optionfiledfont" value="outdoor">
                                  Outdoor
                                </option>
                                <option class="optionfiledfont" value="none">
                                  None
                                </option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-8 col-md-12">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="d-flex for-the-line">
                                  <label
                                    class="res-pt-0"
                                    style="
                                      padding-top: 12px;
                                      padding-right: 10px;
                                    "
                                  >
                                    Parking
                                  </label>

                                  <select
                                    style="text-align: left"
                                    class="
                                      arrows
                                      pakring-set
                                      color-black
                                      res-ml-0 res-parkking
                                    "
                                    v-model="parkingType"
                                  >
                                    <option
                                      class="optionfiledfont"
                                      value="onStreet"
                                    >
                                      On Street
                                    </option>
                                    <option
                                      class="optionfiledfont"
                                      value="offStreet"
                                    >
                                      Off Street
                                    </option>
                                    <option
                                      class="optionfiledfont"
                                      value="allocated"
                                    >
                                      Allocated
                                    </option>
                                    <option
                                      class="optionfiledfont"
                                      value="permitted"
                                    >
                                      Permitted
                                    </option>
                                    <option
                                      class="optionfiledfont"
                                      value="none"
                                    >
                                      No Parking
                                    </option>
                                  </select>
                                  <label
                                    class="res-pt-0 res-display-sm-hide"
                                    style="
                                      padding-top: 12px;
                                      padding-right: 12px;
                                    "
                                  >
                                    For
                                  </label>
                                  <input
                                    @click="inFocus(false)"
                                    v-model="noOfCars"
                                    style="
                                      width: 75px;
                                      height: 50px;
                                      border: 1px solid #ddd;
                                      text-align: center;
                                    "
                                    min="0"
                                    type="number"
                                    class="for-responsive-width d-noneneo"
                                  />

                                  <label
                                    class="d-noneneo"
                                    style="padding-top: 12px; padding-left: 6px"
                                  >
                                    Cars
                                  </label>
                                </div>
                              </div>
                              <div
                                class="col-md-6 res-disply-big-hide res-mt-2"
                              >
                                <div class="d-flex for-the-line">
                                  <label
                                    class="res-pt-0"
                                    style="padding-top: 12px"
                                  >
                                    For
                                  </label>
                                  <div class="d-inline-flex">
                                    <input
                                      @click="inFocus(false)"
                                      v-model="noOfCars"
                                      style="
                                        width: 85% !important;
                                        height: 50px;
                                        border: 1px solid #ddd;
                                        padding: 10px;
                                        margin-top: 0px !important;
                                      "
                                      min="0"
                                      type="number"
                                      class="for-responsive-width"
                                    />
                                    <label
                                      class="res-pt-0"
                                      style="
                                        padding-top: 12px !important;
                                        padding-left: 15px !important;
                                      "
                                    >
                                      Cars
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-6 res-disply-big-hide res-mt-2"
                              >
                                <div class="d-flex for-the-line">
                                  <label
                                    class="res-pt-0"
                                    for="checkbox"
                                    style="padding-top: 13px"
                                  >
                                    <input
                                      id="checkbox"
                                      v-model="isGardenChecked"
                                      type="checkbox"
                                      style="
                                        margin-top: 5px !important;
                                        transform: scale(1.3);
                                        margin-right: 5px;
                                        border: 1px solid rgb(221, 221, 221);
                                      "
                                    />
                                    Garden Approx
                                  </label>
                                  <input
                                    @click="inFocus(false)"
                                    :disabled="!isGardenChecked"
                                    v-model="garden"
                                    style="
                                      width: 100%;
                                      height: 50px;
                                      border: 1px solid #ddd;
                                      padding: 10px;
                                    "
                                    min="0"
                                    type="number"
                                  />
                                  <select
                                    style="
                                      width: 100% !important;
                                      margin-top: 11px;
                                      margin-left: 0px;
                                      text-align: left;
                                      padding: 10px;
                                    "
                                    class="
                                      arrows
                                      pakring-set
                                      color-black
                                      mr-0
                                      onlysafari
                                    "
                                    v-model="gardenArea"
                                  >
                                    <option
                                      class="optionfiledfont"
                                      value="acres"
                                    >
                                      Acres
                                    </option>
                                    <option
                                      class="optionfiledfont"
                                      value="sqMeters"
                                    >
                                      Sq. Meters
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div
                                class="
                                  col-lg-12 col-md-12
                                  mt-3
                                  res-move-mt res-display-sm-hide
                                "
                              >
                                <div class="d-flex">
                                  <input
                                    id="checkbox"
                                    v-model="isGardenChecked"
                                    type="checkbox"
                                    style="
                                      margin-top: 19px !important;
                                      transform: scale(1.5);
                                      border: 1px solid rgb(221, 221, 221);
                                      margin-right: 5px;
                                    "
                                  />
                                  <label
                                    for="checkbox"
                                    style="
                                      padding-top: 13px;
                                      padding-right: 10px;
                                      padding-left: 3px;
                                    "
                                    >Garden Approx
                                  </label>

                                  <input
                                    @click="inFocus(false)"
                                    :disabled="!isGardenChecked"
                                    v-model="garden"
                                    style="
                                      width: 358px;
                                      height: 50px;
                                      border: 1px solid #ddd;
                                      text-align: left;
                                      padding-left: 12px;
                                    "
                                    min="0"
                                    type="number"
                                  />
                                  &nbsp;&nbsp;&nbsp;
                                  <select
                                    style="
                                      width: 111px !important;
                                      padding-left: 0px;
                                    "
                                    class="arrows pakring-set color-black mr-0"
                                    v-model="gardenArea"
                                  >
                                    <option
                                      class="optionfiledfont"
                                      value="acres"
                                    >
                                      Acres
                                    </option>
                                    <option
                                      class="optionfiledfont"
                                      value="sqMeters"
                                    >
                                      Sq. Meters
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center mt-4">
                          <div
                            style="
                              width: 100%;
                              height: 5vh;
                              background-color: #f6f6f6;
                            "
                          ></div>
                        </div>
                        <div class="row ml-1">
                          <button
                            style="color: white"
                            v-if="
                              $refs.formWizard
                                ? $refs.formWizard.activeTabIndex > 0 &&
                                  !$refs.formWizard.isLastStep
                                : false
                            "
                            class="
                              res-back-button
                              btn btn-yellow
                              listing-button-color
                              px-6
                              mt-3
                              w135
                              pull-left
                              setleft
                            "
                            @click="back()"
                          >
                            Back
                          </button>

                          <button
                            style="color: white"
                            v-if="
                              $refs.formWizard
                                ? !$refs.formWizard.isLastStep
                                : true
                            "
                            class="
                              res-next-button
                              btn btn-yellow
                              w135
                              listing-button-color
                              res-b-bacck-1
                              mt-3
                              px-6
                              pull-right
                              ml-auto
                              setleft
                              mr-3
                            "
                            @click="next()"
                          >
                            <b-spinner small v-if="showSpinner"></b-spinner
                            >{{
                              !showSpinner
                                ? $refs.formWizard
                                  ? $refs.formWizard.isLastStep
                                    ? "Create"
                                    : "Next"
                                  : "Next"
                                : ""
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </tab-content>
      <tab-content :before-change="checkDescription" title="Description">
        <div v-if="drafted" class="inner-pages maxw1600 m0a dashboard-bd">
          <!-- Wrapper -->
          <div id="wrapper" class="int_main_wraapper">
            <section class="user-page section-padding pt-5">
              <div class="container">
                <div class="row">
                  <div
                    class="
                      col-lg-12 col-md-12 col-xs-12
                      royal-add-property-area
                      section_100
                      pl-0
                      user-dash2
                    "
                  >
                    <div class="single-add-property">
                      <div class="property-form-group">
                        <form>
                          <div class="row">
                            <div class="col-lg-12 col-md-12">
                              <h3 style="font-weight: 700">
                                Write a short description about your property.
                              </h3>
                              <p class="txt-style font-set">
                                Whether you want to keep it simple or go into
                                more detail, it is important to make sure your
                                descriptons are factual. <br />
                                For more advice on descriptions, read our guide
                                <a
                                  style="color: black"
                                  target="_blank"
                                  href="https://vencasa.com/blog/how-to-write-descriptions-for-your-listing"
                                  ><strong>here</strong></a
                                >.
                              </p>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                              <label class="font-weight-bolder"
                                >Description for Main Page</label
                              >
                              <p>
                                <textarea
                                  @click="inFocus(true)"
                                  :class="
                                    $v.mainDescription.$dirty &&
                                    !$v.mainDescription.required
                                      ? 'error-border'
                                      : ''
                                  "
                                  id="mainDescription"
                                  @change="isDescriptionChanged = true"
                                  @input="inputLimitClipboard"
                                  v-model="mainDescription"
                                  name="pro-dexc"
                                ></textarea>
                                <label class="float-right"
                                  >{{ mainDescription.length }}/250</label
                                >
                              </p>
                              <p
                                class="error mt-n4"
                                v-if="
                                  $v.mainDescription.$dirty &&
                                  !$v.mainDescription.required
                                "
                              >
                                This field is required!
                              </p>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="mainPageDescriptionTitle"
                              />
                              <p>
                                <textarea
                                  @click="inFocus(true)"
                                  id="mainPageDescription"
                                  @change="isDescriptionChanged = true"
                                  v-model="mainPageDescription"
                                  name="pro-dexc"
                                ></textarea>
                              </p>
                            </div>
                            <div
                              v-if="
                                tenure == 'lease' &&
                                !removedList['tenureDescription']
                              "
                              class="col-lg-12 col-md-12 mt-3"
                            >
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="tenureDescriptionTitle"
                              />
                              <p>
                                <textarea
                                  @click="inFocus(true)"
                                  id="tenureDescription"
                                  @change="isDescriptionChanged = true"
                                  v-model="tenureDescription"
                                  name="pro-dexc"
                                ></textarea>
                                <i
                                  @click="
                                    removeOtherThanBathBedOther(
                                      'tenureDescription'
                                    )
                                  "
                                  class="
                                    float-right
                                    mb-4
                                    hover
                                    fas
                                    fa-trash-alt
                                  "
                                ></i>
                              </p>
                            </div>
                            <div
                              class="col-lg-12 col-md-12 mt-3"
                              v-for="room in bedroom"
                              :key="'bedroom' + room.order"
                            >
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="findBedroomDescription(room.order).key"
                              />
                              <p>
                                <textarea
                                  type="text"
                                  v-model="
                                    findBedroomDescription(room.order).value
                                  "
                                ></textarea>
                                <i
                                  @click="
                                    removeBedBathOther(
                                      'bedroom',
                                      findBedroomDescription(room.order).key,
                                      room.order
                                    )
                                  "
                                  class="
                                    float-right
                                    mb-4
                                    hover
                                    fas
                                    fa-trash-alt
                                  "
                                ></i>
                              </p>
                            </div>
                            <div
                              class="col-lg-12 col-md-12 mt-3"
                              v-for="room in bathroom"
                              :key="'bathroom' + room.order"
                            >
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="
                                  findBathroomDescription(room.order).key
                                "
                              />
                              <p>
                                <textarea
                                  type="text"
                                  v-model="
                                    findBathroomDescription(room.order).value
                                  "
                                ></textarea>
                                <i
                                  @click="
                                    removeBedBathOther(
                                      'bathroom',
                                      findBathroomDescription(room.order).key,
                                      room.order
                                    )
                                  "
                                  class="
                                    float-right
                                    mb-4
                                    hover
                                    fas
                                    fa-trash-alt
                                  "
                                ></i>
                              </p>
                            </div>
                            <div
                              class="col-lg-12 col-md-12 mt-3"
                              v-for="room in otherroom"
                              :key="'otherroom' + room.order"
                            >
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="
                                  findOtherroomDescription(room.order).key
                                "
                              />
                              <p>
                                <textarea
                                  type="text"
                                  v-model="
                                    findOtherroomDescription(room.order).value
                                  "
                                ></textarea>
                                <i
                                  @click="
                                    removeBedBathOther(
                                      'otherroom',
                                      findOtherroomDescription(room.order).key,
                                      room.order
                                    )
                                  "
                                  class="
                                    float-right
                                    mb-4
                                    hover
                                    fas
                                    fa-trash-alt
                                  "
                                ></i>
                              </p>
                            </div>
                            <div
                              v-if="
                                !removedList['garageDescription'] &&
                                garage &&
                                garage != 'none'
                              "
                              class="col-lg-12 col-md-12 mt-3"
                            >
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="garageDescriptionTitle"
                              />
                              <p>
                                <textarea
                                  @click="inFocus(true)"
                                  id="garageDescription"
                                  @change="isDescriptionChanged = true"
                                  v-model="garageDescription"
                                  name="pro-dexc"
                                ></textarea>
                                <i
                                  @click="
                                    removeOtherThanBathBedOther(
                                      'garageDescription'
                                    )
                                  "
                                  class="
                                    float-right
                                    mb-4
                                    hover
                                    fas
                                    fa-trash-alt
                                  "
                                ></i>
                              </p>
                            </div>
                            <div
                              v-if="
                                !removedList['swimmingPoolDescription'] &&
                                swimmingPool &&
                                swimmingPool != 'none'
                              "
                              class="col-lg-12 col-md-12 mt-3"
                            >
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="swimmingPoolDescriptionTitle"
                              />
                              <p>
                                <textarea
                                  @click="inFocus(true)"
                                  id="swimmingPoolDescription"
                                  @change="isDescriptionChanged = true"
                                  v-model="swimmingPoolDescription"
                                  name="pro-dexc"
                                ></textarea>
                                <i
                                  @click="
                                    removeOtherThanBathBedOther(
                                      'swimmingPoolDescription'
                                    )
                                  "
                                  class="
                                    float-right
                                    mb-4
                                    hover
                                    fas
                                    fa-trash-alt
                                  "
                                ></i>
                              </p>
                            </div>
                            <div
                              v-if="
                                !removedList['parkingDescription'] &&
                                parkingType &&
                                parkingType != 'none'
                              "
                              class="col-lg-12 col-md-12 mt-3"
                            >
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="parkingDescriptionTitle"
                              />
                              <p>
                                <textarea
                                  @click="inFocus(true)"
                                  id="parkingDescription"
                                  @change="isDescriptionChanged = true"
                                  v-model="parkingDescription"
                                  name="pro-dexc"
                                ></textarea>
                                <i
                                  @click="
                                    removeOtherThanBathBedOther(
                                      'parkingDescription'
                                    )
                                  "
                                  class="
                                    float-right
                                    mb-4
                                    hover
                                    fas
                                    fa-trash-alt
                                  "
                                ></i>
                              </p>
                            </div>
                            <div
                              v-if="
                                !removedList['gardenDescription'] &&
                                garden &&
                                isGardenChecked
                              "
                              class="col-lg-12 col-md-12 mt-3"
                            >
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="gardenDescriptionTitle"
                              />
                              <p>
                                <textarea
                                  @click="inFocus(true)"
                                  id="gardenDescription"
                                  @change="isDescriptionChanged = true"
                                  v-model="gardenDescription"
                                  name="pro-dexc"
                                ></textarea>
                                <i
                                  @click="
                                    removeOtherThanBathBedOther(
                                      'gardenDescription'
                                    )
                                  "
                                  class="
                                    float-right
                                    mb-4
                                    hover
                                    fas
                                    fa-trash-alt
                                  "
                                ></i>
                              </p>
                            </div>
                            <div
                              class="col-lg-12 col-md-12 mt-3"
                              v-for="room in customRooms"
                              :key="'custom' + room.order"
                            >
                              <input
                                class="mb-2 w-100"
                                type="text"
                                v-model="
                                  findCustomroomDescription(room.order).key
                                "
                              />
                              <p>
                                <textarea
                                  type="text"
                                  v-model="
                                    findCustomroomDescription(room.order).value
                                  "
                                ></textarea>
                                <i
                                  @click="
                                    removeCustomBox(
                                      findCustomroomDescription(room.order)
                                        .label,
                                      findCustomroomDescription(room.order)
                                        .order
                                    )
                                  "
                                  class="
                                    float-right
                                    mb-4
                                    hover
                                    fas
                                    fa-trash-alt
                                  "
                                ></i>
                              </p>
                            </div>
                          </div>
                          <div class="d-flex justify-content-end mr-0">
                            <button
                              ref="button1"
                              @click="addToDescription('custom')"
                              style="
                                margin-top: 0px;
                                width: 42px;
                                float: right;
                                color: white;
                              "
                              type="button"
                              class="
                                btn btn-primary
                                listing-button-color
                                res-button-how
                              "
                            >
                              +
                            </button>
                          </div>
                        </form>
                        <div class="d-flex justify-content-center mt-4">
                          <div
                            style="
                              width: 100%;
                              height: 5vh;
                              background-color: #f6f6f6;
                            "
                          ></div>
                        </div>
                        <div class="row ml-1">
                          <button
                            style="color: white"
                            v-if="
                              $refs.formWizard
                                ? $refs.formWizard.activeTabIndex > 0 &&
                                  !$refs.formWizard.isLastStep
                                : false
                            "
                            class="
                              btn btn-yellow
                              listing-button-color
                              res-back-button res-back-button
                              px-6
                              mt-3
                              res-button-back
                              w135
                              pull-left
                              setleft
                            "
                            @click="back()"
                          >
                            Back
                          </button>

                          <button
                            style="color: white"
                            v-if="
                              $refs.formWizard
                                ? !$refs.formWizard.isLastStep
                                : true
                            "
                            class="
                              btn btn-yellow
                              res-next-button
                              w135
                              listing-button-color
                              res-button-next
                              mt-3
                              px-6
                              res-b-set
                              ml-auto
                              setleft
                              mr-3
                            "
                            @click="next()"
                          >
                            <b-spinner small v-if="showSpinner"></b-spinner
                            >{{
                              !showSpinner
                                ? $refs.formWizard
                                  ? $refs.formWizard.isLastStep
                                    ? "Create"
                                    : "Next"
                                  : "Next"
                                : ""
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </tab-content>
      <tab-content :before-change="check2" title="Photos">
        <div v-if="drafted" class="inner-pages maxw1600 m0a dashboard-bd">
          <!-- Wrapper -->
          <div id="wrapper" class="int_main_wraapper">
            <section class="user-page section-padding pt-5">
              <div class="container">
                <div class="row mt-5">
                  <div class="col-lg-12 col-md-12 col-xs-12">
                    <div
                      class="res-remove-paddings-multi"
                      style="padding-left: 66px; padding-right: 80px"
                    >
                      <h3 class="pase" style="font-weight: 700">
                        Add Photos of your property
                      </h3>
                      <p class="text-style-for-mob font-set">
                        Simply take 1 exterior photo of the front of your
                        property, then extra photos to show the interior rooms
                        and garden.<br />
                        Your smartphone or digital camera will work great for
                        this, just make sure it is in landscape orientation.
                      </p>
                      <div
                        style="border-radius: 8px; background-color: #f7901d"
                      >
                        <div
                          style="
                            padding-left: 10px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                          "
                        >
                          <i
                            class="fas fa-exclamation-triangle"
                            style="
                              display: inline;
                              font-size: 33px;
                              padding-top: 3px;
                              color: white;
                              float: left;
                              text-align: left;
                              padding-right: 16px;
                            "
                          ></i>

                          <p
                            style="
                              font-family: 'Lato', sans-serif !important;
                              font-size: 15px !important;
                              font-weight: 400 !important;
                              color: white;

                              padding-bottom: 0px;
                              margin-bottom: 6px;
                              padding-top: 8px;
                            "
                            class="width-fix res-ml-54 font-set"
                          >
                            You can upload photos of your property later. Read
                            our guide on property photography
                            <a
                              style="color: white"
                              target="_blank"
                              href="https://vencasa.com/blog/tips-on-taking-the-best-photos-of-your-listing"
                              ><strong>here</strong></a
                            >.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="
                      col-lg-12 col-md-12 col-xs-12
                      royal-add-property-area
                      section_100
                      pl-0
                      user-dash2
                    "
                  >
                    <div class="single-add-property res-photo-pad">
                      <div class="property-form-group">
                        <div class="row">
                          <div class="col-md-12">
                            <button
                              style="
                                margin-top: 0px;
                                width: 42px;
                                float: right;
                                color: white;
                              "
                              type="button"
                              class="
                                res-hide-on-big
                                mb-2
                                btn btn-primary
                                listing-button-color
                                res-button-how
                              "
                              @click="addPhoto"
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <vue-dropzone
                              @vdropzone-removed-file="fileRemoved"
                              @vdropzone-mounted="dropzoneMounted"
                              @vdropzone-error="onError"
                              ref="dropzone"
                              @vdropzone-success="imageUploadComplete"
                              @vdropzone-sending="sendImages"
                              id="drop1"
                              :options="dropOptions"
                            ></vue-dropzone>
                            <span v-if="showError" class="error"
                              >Only image files can be uploaded, please try
                              again.</span
                            >
                            <span v-if="showLaterMessageImages" class="error"
                              >You can upload photos later.</span
                            >
                            <p
                              class="error"
                              v-if="$v.images.$dirty && !$v.images.required"
                            >
                              Add Images to continue!
                            </p>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center mt-4">
                          <div
                            style="
                              width: 100%;
                              height: 5vh;
                              background-color: #f6f6f6;
                            "
                          ></div>
                        </div>
                        <div class="row ml-1">
                          <button
                            style="color: white"
                            v-if="
                              $refs.formWizard
                                ? $refs.formWizard.activeTabIndex > 0 &&
                                  !$refs.formWizard.isLastStep
                                : false
                            "
                            class="
                              btn btn-yellow
                              listing-button-color
                              res-button-back res-back-button
                              px-6
                              mt-3
                              w135
                              pull-left
                              setleft
                            "
                            @click="back()"
                          >
                            Back
                          </button>

                          <button
                            style="color: white"
                            v-if="
                              $refs.formWizard
                                ? !$refs.formWizard.isLastStep
                                : true
                            "
                            class="
                              btn btn-yellow
                              res-next-button
                              w135
                              listing-button-color
                              res-button-next
                              mt-3
                              px-6
                              pull-right
                              ml-auto
                              setleft
                              mr-3
                            "
                            @click="next()"
                          >
                            <b-spinner small v-if="showSpinner"></b-spinner
                            >{{
                              !showSpinner
                                ? $refs.formWizard
                                  ? $refs.formWizard.isLastStep
                                    ? "Create"
                                    : "Next"
                                  : "Next"
                                : ""
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </tab-content>
      <tab-content :before-change="checkImage" title="Floor Plan">
        <div v-if="drafted" class="inner-pages maxw1600 m0a dashboard-bd">
          <!-- Wrapper -->
          <div id="wrapper" class="int_main_wraapper">
            <section class="user-page section-padding pt-5">
              <div class="container">
                <div class="row mt-5">
                  <div class="col-lg-12 col-md-12 col-xs-12">
                    <div
                      class="res-remove-paddings-multi"
                      style="padding-left: 66px; padding-right: 80px"
                    >
                      <h3 style="font-weight: 700">Upload your Floorplan</h3>

                      <div
                        style="border-radius: 8px; background-color: #f7901d"
                        class="yellew-text"
                      >
                        <div
                          style="
                            padding-left: 10px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                          "
                        >
                          <i
                            class="fas fa-exclamation-triangle"
                            style="
                              display: inline;
                              font-size: 33px;
                              padding-top: 3px;
                              color: white;
                              float: left;
                              text-align: left;
                              padding-right: 16px;
                            "
                          ></i>

                          <p
                            class="font-set"
                            style="
                              color: white;
                              font-size: 21px;
                              padding-bottom: 0px;
                              margin-bottom: 6px;
                              padding-top: 8px;
                            "
                          >
                            You can upload photos of your floorplan later. Read
                            our guide on creating a floorplan
                            <a
                              style="color: white"
                              target="_blank"
                              href="https://vencasa.com/blog/how-to-create-a-floor-plan"
                              ><strong>here</strong></a
                            >.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      col-lg-12 col-md-12 col-xs-12
                      royal-add-property-area
                      section_100
                      pl-0
                      user-dash2
                    "
                  >
                    <div class="single-add-property res-photo-pad">
                      <div class="property-form-group">
                        <div class="row">
                          <div class="col-md-12">
                            <button
                              style="
                                margin-top: 0px;
                                width: 42px;
                                float: right;
                                color: white;
                              "
                              type="button"
                              class="
                                res-hide-on-big
                                mb-2
                                btn btn-primary
                                listing-button-color
                                res-button-how
                              "
                              @click="addPhotoFloorPlan"
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div class="row d-flex justify-content-center">
                          <div class="col-lg-12 col-md-12">
                            <vue-dropzone
                              @vdropzone-removed-file="fileRemoved2"
                              @vdropzone-mounted="dropzoneMountedFloorPlan"
                              ref="dropzone2"
                              @vdropzone-success="imageUploadComplete2"
                              @vdropzone-sending="sendImages2"
                              id="drop1"
                              :options="dropOptionsFloorPlan"
                            ></vue-dropzone>
                            <span v-if="showLaterMessageFloorPlan" class="error"
                              >You can upload floor plan later.</span
                            >
                            <p
                              class="error"
                              v-if="
                                $v.floorPlan.$dirty && !$v.floorPlan.required
                              "
                            >
                              Add Floor Plan to continue!
                            </p>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center mt-4">
                          <div
                            style="
                              width: 100%;
                              height: 5vh;
                              background-color: #f6f6f6;
                            "
                          ></div>
                        </div>
                        <button
                          style="color: white"
                          v-if="
                            $refs.formWizard
                              ? $refs.formWizard.activeTabIndex > 0 &&
                                !$refs.formWizard.isLastStep
                              : false
                          "
                          class="
                            btn btn-yellow
                            listing-button-color
                            res-button-back res-back-button
                            px-6
                            mt-3
                            pull-left
                          "
                          @click="back()"
                        >
                          Back
                        </button>
                        <button
                          style="color: white"
                          v-if="
                            $refs.formWizard
                              ? !$refs.formWizard.isLastStep
                              : true
                          "
                          class="
                            btn btn-yellow
                            listing-button-color
                            res-next-button res-button-next
                            mt-3
                            px-6
                            pull-right
                          "
                          @click="next()"
                        >
                          <b-spinner small v-if="showSpinner"></b-spinner
                          >{{
                            !showSpinner
                              ? $refs.formWizard
                                ? $refs.formWizard.isLastStep
                                  ? "Create"
                                  : "Next"
                                : "Next"
                              : ""
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </tab-content>
      <tab-content :before-change="checkEpc" title="EPC Certificate">
        <div v-if="drafted" class="inner-pages maxw1600 m0a dashboard-bd">
          <!-- Wrapper -->
          <div id="wrapper" class="int_main_wraapper">
            <section class="user-page section-padding pt-5">
              <div class="container">
                <div class="row mt-5">
                  <div class="col-lg-12 col-md-12 col-xs-12">
                    <div
                      class="res-remove-paddings-multi"
                      style="padding-left: 66px; padding-right: 80px"
                    >
                      <h3 class="pase" style="font-weight: 700">
                        Select your EPC rating
                      </h3>
                      <p class="text-style-for-mob font-set">
                        Every house in the UK is rated for it's enegy
                        efficiency, this is called EPC rating. There are local
                        companies who will be able to help you to get your
                        rating.
                      </p>

                      <div
                        style="border-radius: 8px; background-color: #f7901d"
                        class="width-fix12"
                      >
                        <div
                          style="
                            padding-left: 10px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                          "
                        >
                          <i
                            class="fas fa-exclamation-triangle"
                            style="
                              display: inline;
                              font-size: 33px;
                              padding-top: 14px;
                              color: white;
                              float: left;
                              text-align: left;
                              padding-right: 16px;
                            "
                          ></i>

                          <p
                            class="font-set"
                            style="
                              color: white;
                              font-size: 14px;
                              padding-bottom: 0px;
                              margin-bottom: 6px;
                              padding-top: 8px;
                            "
                          >
                            You can list your propery without an EPC
                            Certificate, however it is a legal requiement to
                            provide one in order to accept an offer on your
                            Property. You can add one to your lisitng later.
                            <br />
                            <br />
                          </p>

                          <p
                            class="res-pl-0 font-set"
                            style="
                              padding-left: 49px;
                              font-family: 'Lato', sans-serif;
                              color: white;
                              font-size: 15px;
                              font-weight: 600;
                            "
                          >
                            Click 'Next' to proceed.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      col-lg-12 col-md-12 col-xs-12
                      royal-add-property-area
                      section_100
                      pl-0
                      user-dash2
                    "
                  >
                    <div class="single-add-property res-photo-pad">
                      <div class="property-form-group">
                        <div class="row d-flex justify-content-center">
                          <div class="col-lg-12 col-md-12">
                            <select
                              @click="inFocus(false)"
                              class="
                                color-black
                                form-select
                                pro-status
                                dropdown-toggle
                                arrows
                              "
                              place
                              v-model="epcRating"
                              name=""
                              id=""
                            >
                              <option value="" disabled selected>
                                Select EPC Rating
                              </option>
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                              <option value="D">D</option>
                              <option value="E">E</option>
                              <option value="F">F</option>
                              <option value="G">G</option>
                            </select>
                            <p
                              class="error"
                              v-if="
                                $v.epcRating.$dirty && !$v.epcRating.required
                              "
                            >
                              This field is required!
                            </p>
                            <div class="row mt-2">
                              <div class="col-md-12">
                                <button
                                  style="
                                    margin-top: 0px;
                                    width: 42px;
                                    float: right;
                                    color: white;
                                  "
                                  type="button"
                                  class="
                                    res-hide-on-big
                                    btn btn-primary
                                    listing-button-color
                                    res-button-how
                                  "
                                  @click="addPhotoEpc"
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <vue-dropzone
                              class="mt-3"
                              @vdropzone-removed-file="fileRemoved3"
                              @vdropzone-mounted="dropzoneMountedEpc"
                              ref="dropzone3"
                              @vdropzone-success="imageUploadComplete3"
                              @vdropzone-sending="sendImages3"
                              @vdropzone-file-added="dropping"
                              id="drop1"
                              :options="dropOptionsEpc"
                              :useCustomSlot="showWarningMessage"
                            >
                              <div class="dropzone-custom-content">
                                <p class="error text-left">
                                  You can list your property without an EPC
                                  certificate. However it is a legal requirement
                                  to provide one in order to accept an offer on
                                  your property.
                                </p>
                                <p class="error text-left">
                                  You can return and add one to your listing
                                  later.
                                </p>
                                <p class="error text-left">
                                  Click Next to proceed.
                                </p>
                              </div>
                            </vue-dropzone>
                            <p class="text-style-for-mob mt-5 font-set">
                              It is a legal requirement to provide an Energy
                              Performance Certificate for buyers to view if you
                              are selling a property in the UK.
                              <br />
                              <br />
                              An EPC certificate is valid for 10 years, so if
                              you already have one in date you can use that. You
                              can use the government's EPC checker online to see
                              if your property has one.
                              <br />
                              <br />
                              Alternatively if you do not have one you will need
                              a 3rd party to assess your property.
                              <br />
                              <br />
                              Please note that you can list a property without
                              an EPC but you will be unable to accept an offer
                              on your property until you have uploaded the
                              properties EPC. This can be done later by editing
                              the listing.
                              <br />
                              <br />
                              For properties in Scotland, your EPC will
                              typically be included in your 'Home Report',
                              however you can also attain an EPC independent of
                              your Home Report. You can check to see if you
                              already have a valid EPC by searching the Scottish
                              Energy Performance Register online.
                            </p>
                            <p
                              class="error"
                              v-if="$v.epcFile.$dirty && !$v.epcFile.required"
                            >
                              Add EPC Certificate to continue!
                            </p>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center mt-4">
                          <div
                            style="
                              width: 100%;
                              height: 5vh;
                              background-color: #f6f6f6;
                            "
                          ></div>
                        </div>
                        <div class="row ml-1">
                          <button
                            style="color: white"
                            v-if="
                              $refs.formWizard
                                ? $refs.formWizard.activeTabIndex > 0 &&
                                  !$refs.formWizard.isLastStep
                                : false
                            "
                            class="
                              btn btn-yellow
                              listing-button-color
                              px-6
                              mt-3
                              res-button-back res-back-button
                              w135
                              pull-left
                              setleft
                            "
                            @click="back()"
                          >
                            Back
                          </button>

                          <button
                            style="color: white"
                            v-if="
                              $refs.formWizard
                                ? !$refs.formWizard.isLastStep
                                : true
                            "
                            class="
                              btn btn-yellow
                              w135
                              res-next-button
                              listing-button-color
                              mt-3
                              px-6
                              res-button-next
                              pull-right
                              ml-auto
                              setleft
                              mr-3
                            "
                            @click="next()"
                          >
                            <b-spinner small v-if="showSpinner"></b-spinner
                            >{{
                              !showSpinner
                                ? $refs.formWizard
                                  ? $refs.formWizard.isLastStep
                                    ? "Create"
                                    : "Next"
                                  : "Next"
                                : ""
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </tab-content>
      <tab-content ref="summaryTab" title="Summary">
        <div
          v-if="imagesAdded && drafted"
          class="inner-pages maxw1600 m0a dashboard-bd"
        >
          <!-- Wrapper -->
          <div id="wrapper" class="int_main_wraapper">
            <section class="user-page section-padding pt-5">
              <div class="container">
                <div class="row">
                  <div
                    class="
                      col-lg-12 col-md-12 col-xs-12
                      royal-add-property-area
                      section_100
                      pl-0
                      user-dash2
                    "
                  >
                    <div
                      style="box-shadow: rgb(71 85 95 / 8%) 0px 0px 10px 1px"
                      class="
                        single-add-property
                        properties-list
                        paddingset
                        full
                        featured
                        portfolio
                        blog
                      "
                    >
                      <!-- remcving property-form-group -->

                      <div class="">
                        <div class="row featured portfolio-items">
                          <div class="col-lg-12 col-md-12 mt-4">
                            <simple-list-property
                              :fromAddProperty="true"
                              :property="{
                                images: images,
                                noOfBathrooms: bathroom.length,
                                county: county,
                                city: city,
                                street: street,
                                noOfBedrooms: bedroom.length,
                                houseNo: houseNo,
                                price: parseInt(price.replace(/,/g, '')),
                                epcCertificate: { epcRating: epcRating },
                                description: [
                                  { mainDescription: mainDescription },
                                ],
                              }"
                            >
                              <template #title>
                                <span>
                                  <i class="fa fa-map-marker"></i
                                  >{{
                                    `${city}, ${postCode.split(" ")[0]}${
                                      county ? `, ${county}` : ""
                                    }`
                                  }}
                                </span>
                              </template>
                              <template #actions>
                                <i
                                  :class="[
                                    'fas',
                                    'fa-heart',
                                    'res-icon-width',
                                    'stroke-transparent',
                                  ]"
                                  :style="'width: 100%; text-align: end; font-size: 30px'"
                                ></i>
                              </template>
                              <template #descriptionTitle="{ property }">
                                <div
                                  style="
                                    height: 91px !important;
                                    overflow-wrap: anywhere;
                                  "
                                >
                                  <span style="text-align: justify">
                                    {{
                                      property.description
                                        ? property.description[0]
                                          ? `${sliceString(
                                              property.description[0][
                                                "mainDescription"
                                              ]
                                            )}`
                                          : ""
                                        : ""
                                    }}<button
                                      class="button-link"
                                      @click="scrollToMain"
                                      v-if="
                                        (property.description
                                          ? property.description[0]
                                            ? property.description[0][
                                                'mainDescription'
                                              ]
                                            : ''
                                          : ''
                                        ).length > 250
                                      "
                                    >
                                      ...more
                                    </button></span
                                  >
                                </div>
                              </template>
                              <template v-if="screenWidth >= 760" #buttons>
                                <button
                                  disabled
                                  style="
                                    width: 25%;
                                    padding: 10px 0px;
                                    border: none;
                                    background-color: rgb(103 155 91);
                                    color: #fff;
                                    border-radius: 5px;
                                    font-size: 15px;
                                  "
                                >
                                  View
                                </button>
                                <button
                                  disabled
                                  style="
                                    width: 25%;
                                    padding: 10px 0px;
                                    border: none;
                                    background-color: rgb(103 155 91);
                                    color: #fff;
                                    border-radius: 5px;
                                    font-size: 15px;
                                  "
                                >
                                  Enquire
                                </button>
                              </template>
                              <template v-if="screenWidth <= 760" #buttonsAlt>
                                <button
                                  disabled
                                  style="
                                    width: 100%;
                                    margin-top: 5px;
                                    margin-bottom: 15px;
                                    padding: 10px 0px;
                                    border: none;
                                    background-color: rgb(103 155 91);
                                    color: #fff;
                                    border-radius: 5px;
                                    font-size: 15px;
                                  "
                                >
                                  View
                                </button>
                                <button
                                  disabled
                                  style="
                                    width: 100%;
                                    margin-top: 5px;
                                    margin-bottom: 15px;
                                    padding: 10px 0px;
                                    border: none;
                                    background-color: rgb(103 155 91);
                                    color: #fff;
                                    border-radius: 5px;
                                    font-size: 15px;
                                  "
                                >
                                  Enquire
                                </button>
                              </template>
                              <template #createdAt>
                                <p class="d-none"></p>
                              </template>
                            </simple-list-property>
                          </div>
                          <div class="col-lg-12 col-md-12 mt-4">
                            <div
                              style="
                                border-radius: 8px 8px 8px 8px;
                                justify-content: center;
                              "
                              class="homes-content details row m-0 mb-30"
                            >
                              <div
                                v-if="type !== 'none'"
                                class="col-md-2 text-center res-margin-imgaes"
                              >
                                <img
                                  class="
                                    custome-images-icon
                                    res-sm-img res-img-w
                                  "
                                  :src="
                                    require('../assets/images/icons/Home-Icon.webp')
                                  "
                                />
                                <p style="font-weight: bold" class="m-0">
                                  Property Type:
                                </p>
                                <span
                                  >{{ capitalize(drafted.propertyType) }}
                                </span>
                              </div>
                              <div
                                class="col-md-2 text-center res-margin-imgaes"
                              >
                                <img
                                  class="
                                    custome-images-icon
                                    res-sm-img res-img-w
                                  "
                                  :src="
                                    require('../assets/images/icons/Tenure-Icon.webp')
                                  "
                                />
                                <p style="font-weight: bold" class="m-0">
                                  Tenure:
                                </p>
                                <span>
                                  {{ capitalize(drafted.tenure) }}hold</span
                                >
                              </div>
                              <div
                                v-if="drafted.garage && garage != 'none'"
                                class="col-md-2 text-center res-margin-imgaes"
                              >
                                <img
                                  class="
                                    custome-images-icon
                                    res-sm-img res-img-w
                                  "
                                  :src="
                                    require('../assets/images/icons/Garage-Icon.webp')
                                  "
                                />
                                <p style="font-weight: bold" class="m-0">
                                  Garage:
                                </p>
                                <span>{{ capitalize(drafted.garage) }}</span>
                              </div>
                              <div
                                v-if="drafted.parking && parkingType != 'none'"
                                class="col-md-2 text-center res-margin-imgaes"
                              >
                                <img
                                  class="
                                    custome-images-icon
                                    res-sm-img res-img-w
                                  "
                                  :src="
                                    require('../assets/images/icons/Parking-Icon.webp')
                                  "
                                />
                                <p style="font-weight: bold" class="m-0">
                                  Parking:
                                </p>
                                <span
                                  >{{ capitalize(enums[drafted.parking]) }}
                                  {{ drafted.noOfCars }}
                                  {{
                                    `car${drafted.noOfCars == 1 ? "" : "s"}`
                                  }}</span
                                >
                              </div>
                              <div
                                v-if="drafted.garden"
                                class="col-md-2 text-center res-margin-imgaes"
                              >
                                <img
                                  class="
                                    custome-images-icon
                                    res-sm-img res-img-w
                                  "
                                  :src="
                                    require('../assets/images/icons/Garden-Icon.webp')
                                  "
                                />
                                <p style="font-weight: bold" class="m-0">
                                  Garden:
                                </p>
                                <span v-if="drafted.garden != 'No'"
                                  >{{ capitalize(drafted.garden) }}
                                  {{
                                    drafted.gardenArea == "acres"
                                      ? "Acres"
                                      : "Sq. Meters"
                                  }}</span
                                >
                                <span v-else>None</span>
                              </div>
                              <div
                                v-if="
                                  drafted.swimmingPool && swimmingPool != 'none'
                                "
                                class="col-md-2 text-center res-margin-imgaes"
                              >
                                <img
                                  class="
                                    custome-images-icon
                                    res-sm-img res-img-w
                                  "
                                  :src="
                                    require('../assets/images/icons/Swimming-Pool-Icon.webp')
                                  "
                                />
                                <p style="font-weight: bold" class="m-0">
                                  Swimming Pool:
                                </p>
                                <span v-if="drafted.swimmingPool != 'No'">{{
                                  capitalize(drafted.swimmingPool)
                                }}</span>
                                <span v-else>None</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12">
                            <div class="mt-5">
                              <div
                                v-if="
                                  _.get(drafted, 'floorPlan', []).length ||
                                  _.get(
                                    drafted,
                                    'epcCertificate.epcDocument',
                                    []
                                  ).length
                                "
                                style="border-radius: 8px 8px 8px 8px"
                                class="
                                  floor-plan
                                  property
                                  wprt-image-video
                                  w50
                                  pro
                                "
                              >
                                <div
                                  v-if="
                                    _.get(drafted, 'floorPlan', []).length ||
                                    _.get(
                                      drafted,
                                      'epcCertificate.epcDocument',
                                      []
                                    ).length
                                  "
                                  class="row"
                                >
                                  <div
                                    class="col-md-4 mb-5"
                                    v-for="(image, index) in drafted.floorPlan"
                                    :key="index"
                                    style="height: 300px; width: 319px"
                                  >
                                    <img
                                      @click="enlargeImage('floorPlan', image)"
                                      style="
                                        width: 100%;
                                        height: 100%;
                                        object-fit: fill;
                                      "
                                      class="mb-3"
                                      alt="image"
                                      :src="`${image}`"
                                    />
                                  </div>
                                  <div
                                    style="height: 300px; width: 319px"
                                    v-if="drafted.epcCertificate"
                                  >
                                    <img
                                      @click="enlargeImage('epcCertificate')"
                                      v-if="
                                        drafted.epcCertificate.epcDocument
                                          .length &&
                                        getExtension(
                                          drafted.epcCertificate.epcDocument[0]
                                        ).includes('png')
                                      "
                                      style="
                                        width: 100%;
                                        height: 100%;
                                        object-fit: fill;
                                      "
                                      alt="image"
                                      :src="
                                        drafted.epcCertificate
                                          ? `${drafted.epcCertificate.epcDocument}`
                                          : ''
                                      "
                                    />
                                    <a
                                      v-else-if="
                                        getExtension(
                                          drafted.epcCertificate.epcDocument[0]
                                        ).includes('pdf')
                                      "
                                      target="_blank"
                                      :href="`${drafted.epcCertificate.epcDocument[0]}`"
                                      >{{
                                        drafted.epcCertificate.epcDocument[0]
                                      }}</a
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-12 col-md-12 blog-pots">
                                <div
                                  style="border-radius: 8px 8px 8px 8px     place-content: center;"
                                  class="single homes-content details mb-30"
                                >
                                  <h3>
                                    {{ street }}, {{ city }},
                                    {{ postCode }}
                                  </h3>
                                  <div class="d-flex justify-content-center">
                                    <GmapMap
                                      :center="{ lat: lat, lng: lng }"
                                      :zoom="15"
                                      map-type-id="terrain"
                                      style="width: 100%; height: 500px"
                                    >
                                      <GmapMarker
                                        :position="{ lat: lat, lng: lng }"
                                      />
                                    </GmapMap>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div
                                  style="border-radius: 8px 8px 8px 8px"
                                  class="blog-info details mb-30"
                                >
                                  <h5 class="mb-4 main-description">
                                    About Property
                                  </h5>
                                  <div class="mb-4">
                                    {{
                                      drafted.description[0]
                                        ? drafted.description[0].value[
                                            Object.keys(
                                              drafted.description[0].value
                                            )[0]
                                          ]
                                        : ""
                                    }}
                                  </div>
                                  <h5 class="mb-4">Description</h5>
                                  <div
                                    class="mb-3"
                                    v-for="(
                                      description, index
                                    ) in drafted.description"
                                    :key="index"
                                  >
                                    <div
                                      class="mb-4"
                                      v-if="
                                        description.value[
                                          Object.keys(description.value)[0]
                                        ] &&
                                        description.removed !== 'true' &&
                                        Object.keys(description.value)[0] !==
                                          `mainDescription` &&
                                        (Object.keys(description.value)[0] ==
                                        `tenureDescription`
                                          ? drafted.tenure == 'lease'
                                          : true)
                                      "
                                    >
                                      <h3>
                                        {{ Object.keys(description.value)[0] }}:
                                      </h3>
                                      <p style="white-space: break-spaces">
                                        {{
                                          description.value[
                                            Object.keys(description.value)[0]
                                          ]
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <!-- End Description -->
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center mt-4">
                          <div
                            style="
                              width: 100%;
                              height: 5vh;
                              background-color: #f6f6f6;
                            "
                          ></div>
                        </div>
                        <div class="row ml-1">
                          <button
                            style="color: white"
                            v-if="
                              $refs.formWizard
                                ? $refs.formWizard.activeTabIndex > 0 &&
                                  !$refs.formWizard.isLastStep
                                : false
                            "
                            class="
                              btn btn-yellow
                              listing-button-color
                              px-6
                              mt-3
                              w135
                              pull-left
                              res-button-back res-back-button
                              setleft
                            "
                            @click="back()"
                          >
                            Back
                          </button>

                          <button
                            style="color: white"
                            v-if="
                              $refs.formWizard
                                ? !$refs.formWizard.isLastStep
                                : true
                            "
                            class="
                              btn btn-yellow
                              w135
                              listing-button-color
                              res-next-button
                              mt-3
                              px-6
                              pull-right
                              res-button-next
                              ml-auto
                              setleft
                              mr-3
                            "
                            @click="next()"
                          >
                            <b-spinner small v-if="showSpinner"></b-spinner
                            >{{
                              !showSpinner
                                ? $refs.formWizard
                                  ? $refs.formWizard.isLastStep
                                    ? "Create"
                                    : "Next"
                                  : "Next"
                                : ""
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </tab-content>
      <tab-content title="Terms and Conditions">
        <div v-if="drafted" class="inner-pages maxw1600 m0a dashboard-bd">
          <!-- Wrapper -->
          <div id="wrapper" class="int_main_wraapper">
            <section class="user-page section-padding pt-5">
              <div class="container">
                <div class="row">
                  <div
                    class="
                      col-lg-12 col-md-12 col-xs-12
                      royal-add-property-area
                      section_100
                      pl-0
                      user-dash2
                    "
                  >
                    <div class="single-add-property">
                      <div class="property-form-group">
                        <h3
                          data-v-4f8ed385=""
                          class="pase text-center"
                          style="font-weight: 700; font-size: 25px"
                        >
                          Terms and Conditions
                        </h3>
                        <div
                          style="
                            height: 400px !important;
                            width: 100%;
                            border: 2px solid rgb(246, 246, 246);
                            margin-left: 0%;
                          "
                          class="row scrolls h-auto"
                        >
                          <div
                            v-html="inner"
                            style="margin-top: 20px"
                            class="col-lg-12 col-md-12"
                          ></div>
                        </div>
                        <div class="d-flex justify-content-center mt-4">
                          <div
                            style="
                              width: 100%;
                              height: 5vh;
                              background-color: #f6f6f6;
                            "
                          ></div>
                        </div>
                        <button
                          @click="submit"
                          :disabled="delayCreate"
                          style="width: 196px; color: white"
                          class="
                            res-w-1oo
                            btn btn-yellow
                            listing-button-color
                            mt-3
                            px-6
                            pull-right
                          "
                        >
                          <b-spinner small v-if="showSpinner"></b-spinner
                          >{{ !showSpinner ? "Accept" : "" }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </tab-content>
      <tab-content title="Payment">
        <div>
          <div v-if="!shouldShow && packages.length">
            <div class="inner-pages maxw1600 m0a dashboard-bd">
              <div id="wrapper" class="int_main_wraapper">
                <section class="user-page section-padding pt-5">
                  <div class="container">
                    <div class="row">
                      <div
                        class="
                          col-lg-12 col-md-12 col-xs-12
                          royal-add-property-area
                          section_100
                          pl-0
                          user-dash2
                        "
                      >
                        <div class="single-add-property">
                          <div class="property-form-group">
                            <div class="row">
                              <div class="col-lg-12 col-md-12 respadiing">
                                <div class="row justify-content-md-center">
                                  <div class="col-md-6">
                                    <h6
                                      style="
                                        font-weight: bold;
                                        font-size: 23px;
                                        color: black;
                                      "
                                      class="text-center mb-4"
                                    >
                                      Build your Package
                                    </h6>

                                    <div
                                      class="text-center"
                                      style="
                                        border: 3px solid;
                                        font-weight: bold;
                                        margin-bottom: 20px;
                                        border-color: rgb(84, 143, 77);
                                        border-radius: 8px;
                                      "
                                    >
                                      <div class="p-3">
                                        <h5
                                          style="
                                            font-weight: bold;
                                            color: black;
                                          "
                                        >
                                          The One
                                        </h5>
                                        <h5
                                          style="
                                            font-size: 38px;
                                            font-weight: bold;
                                            color: #548f4d;
                                          "
                                        >
                                          £{{ theOne() }}
                                        </h5>
                                        <h6
                                          style="
                                            font-weight: bold;
                                            color: black;
                                          "
                                        >
                                          WHAT'S INCLUDED
                                        </h6>
                                        <p
                                          class="mt-4"
                                          style="
                                            margin-bottom: 10px;
                                            font-weight: 600;
                                            color: black;
                                          "
                                        >
                                          Direct Contact with Potential Buyers
                                        </p>
                                        <p
                                          style="
                                            margin-bottom: 10px;
                                            font-weight: 600;
                                            color: black;
                                          "
                                        >
                                          List Until Sold
                                        </p>
                                        <p
                                          style="
                                            margin-bottom: 10px;
                                            font-weight: 600;
                                            color: black;
                                          "
                                        >
                                          Offers Sent Instantly
                                        </p>
                                        <p
                                          style="
                                            margin-bottom: 10px;
                                            font-weight: 600;
                                            color: black;
                                          "
                                        >
                                          Unlimited viewings at Times to Suit
                                          You
                                        </p>
                                        <p
                                          style="
                                            margin-bottom: 10px;
                                            font-weight: 600;
                                            color: black;
                                          "
                                        >
                                          You Keep Full Control
                                        </p>
                                        <hr />
                                        <h5
                                          class="mb-4"
                                          style="
                                            font-weight: bold;
                                            font-size: 23px;
                                            color: black;
                                          "
                                        >
                                          The Optional Extras
                                        </h5>
                                        <div
                                          v-if="packages[0].isPremiumListing"
                                          class="row"
                                        >
                                          <div class="col-md-6">
                                            <p class="pre-listing float-left">
                                              Premium Lisitng
                                            </p>
                                            <br />
                                            <span
                                              class="
                                                payment-add-setinumber
                                                span-color
                                                float-left
                                                res-set-span-payment
                                              "
                                              >£{{
                                                packages[0].premiumListingPrice
                                              }}</span
                                            >
                                          </div>

                                          <div class="col-md-6">
                                            <button
                                              style="color: white"
                                              @click="
                                                addOptionalExtra(
                                                  'premiumListingPrice'
                                                )
                                              "
                                              class="
                                                btn btn-payment
                                                addButtonPro
                                                res-button-how
                                              "
                                            >
                                              {{
                                                optionalExtra[
                                                  "premiumListingPrice"
                                                ]
                                                  ? "Added"
                                                  : "Add"
                                              }}
                                            </button>
                                          </div>
                                        </div>
                                        <hr />

                                        <div
                                          v-if="packages[0].isForSaleSign"
                                          class="row"
                                        >
                                          <div class="col-md-8">
                                            <p
                                              style="color: black"
                                              class="pre-listing float-left"
                                            >
                                              For Sale Sign Sent to You
                                            </p>
                                            <br />
                                            <span
                                              class="
                                                payment-add-setinumber
                                                span-color
                                                float-left
                                                res-set-span-payment
                                              "
                                              >£{{
                                                packages[0].forSaleSignPrice
                                              }}</span
                                            >
                                          </div>

                                          <div class="col-md-4">
                                            <button
                                              style="color: white"
                                              @click="
                                                addOptionalExtra(
                                                  'forSaleSignPrice'
                                                )
                                              "
                                              class="
                                                btn btn-payment
                                                addButtonPro
                                                res-button-how
                                              "
                                            >
                                              {{
                                                optionalExtra[
                                                  "forSaleSignPrice"
                                                ]
                                                  ? "Added"
                                                  : "Add"
                                              }}
                                            </button>
                                          </div>
                                        </div>
                                        <hr v-if="packages[0].isForSaleSign" />
                                        <div
                                          v-if="
                                            packages[0].isCharitableDonation
                                          "
                                          class="row"
                                        >
                                          <div class="col-md-6">
                                            <p
                                              style="color: black"
                                              class="pre-listing float-left"
                                            >
                                              Offset Your Move
                                            </p>
                                            <br />
                                            <span
                                              class="
                                                payment-add-setinumber
                                                span-color
                                                float-left
                                                res-set-span-payment
                                              "
                                              >£{{
                                                parseFloat(
                                                  packages[0]
                                                    .charitableDonationPrice
                                                ).toFixed(2)
                                              }}</span
                                            >
                                          </div>

                                          <div class="col-md-6">
                                            <button
                                              style="color: white"
                                              @click="
                                                addOptionalExtra(
                                                  'charitableDonationPrice'
                                                )
                                              "
                                              class="
                                                btn btn-payment
                                                addButtonPro
                                                res-button-how
                                              "
                                            >
                                              {{
                                                optionalExtra[
                                                  "charitableDonationPrice"
                                                ]
                                                  ? "Added"
                                                  : "Add"
                                              }}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-md-6 res-padding-31">
                                    <h6
                                      style="
                                        font-weight: bold;
                                        font-size: 23px;
                                        color: black;
                                      "
                                      class="mb-4 text-center"
                                    >
                                      Payment Details
                                    </h6>

                                    <div
                                      class="text-center"
                                      style="
                                        border: 3px solid;
                                        margin-bottom: 20px;
                                        border-color: rgb(84, 143, 77);
                                        border-radius: 8px;
                                      "
                                    >
                                      <div class="p-3">
                                        <h5
                                          class="d-none"
                                          style="
                                            font-weight: bold;
                                            color: black;
                                          "
                                        >
                                          The One
                                        </h5>
                                        <div class="row mb-3 mt-3">
                                          <p
                                            style="
                                              font-weight: 500;
                                              color: black;
                                            "
                                            class="
                                              col-md-6
                                              mb-0
                                              text-left
                                              res-ww-50
                                            "
                                          >
                                            Payment Due (Excl. VAT):
                                          </p>
                                          <p
                                            style="
                                              color: rgb(84, 143, 77);
                                              font-weight: 700;
                                              font-size: 20px;
                                            "
                                            class="
                                              col-md-6
                                              mb-0
                                              text-right
                                              res-ww-50
                                            "
                                          >
                                            £{{ (theOne() / 1.2).toFixed(2) }}
                                          </p>
                                        </div>
                                        <div class="row mb-3">
                                          <p
                                            style="
                                              font-weight: 500;
                                              color: black;
                                            "
                                            class="
                                              col-md-6
                                              mb-0
                                              text-left
                                              res-ww-50
                                            "
                                          >
                                            Coupon Code:
                                          </p>
                                          <div
                                            class="
                                              col-md-6
                                              text-right
                                              res-ww-50
                                            "
                                          >
                                            <input
                                              class="w-55-coupon"
                                              v-model="couponCode"
                                              type="text "
                                              style="
                                                width: 150px;
                                                text-align: right;
                                              "
                                            />
                                            <p
                                              v-if="
                                                showCouponError
                                                  ? !couponValue
                                                    ? true
                                                    : false
                                                  : false
                                              "
                                              class="error"
                                            >
                                              code is invalid
                                            </p>
                                          </div>
                                        </div>
                                        <div class="row mb-3">
                                          <p
                                            style="
                                              font-weight: 500;
                                              color: black;
                                            "
                                            class="
                                              col-md-6
                                              mb-0
                                              text-left
                                              res-ww-60
                                            "
                                          >
                                            Coupon Discount:
                                          </p>
                                          <p
                                            style="
                                              color: rgb(84, 143, 77);
                                              font-weight: 700;
                                              font-size: 20px;
                                            "
                                            class="
                                              col-md-6
                                              mb-0
                                              text-right
                                              res-ww-40
                                            "
                                          >
                                            {{
                                              couponValue
                                                ? `${
                                                    enumsDiscountType[
                                                      couponValue.discountType
                                                    ]
                                                  } ${couponValue.discount}`
                                                : "0%"
                                            }}
                                          </p>
                                        </div>
                                        <div v-if="showRedeem" class="row mb-3">
                                          <div
                                            style="
                                              font-weight: 500;
                                              color: black;
                                            "
                                            class="col-md-6"
                                          >
                                            Redeem:
                                          </div>
                                          <div class="col-md-6">
                                            <input
                                              v-model="isChecked"
                                              class="align-self-center m-set"
                                              type="checkbox"
                                            />
                                          </div>
                                        </div>
                                        <div v-if="isChecked" class="row mb-3">
                                          <div
                                            style="
                                              font-weight: 500;
                                              color: black;
                                            "
                                            class="col-md-6"
                                          >
                                            Redeem Reward:
                                          </div>
                                          <div class="col-md-6">
                                            £{{ redeemReward }}
                                          </div>
                                        </div>
                                        <div class="row mb-2">
                                          <div class="col align-self-end">
                                            <h6
                                              style="
                                                font-weight: bold;
                                                color: black;
                                              "
                                              class="text-uppercase float-right"
                                            >
                                              Total (Excl. vat)
                                            </h6>
                                          </div>
                                        </div>
                                        <div class="row mb-3">
                                          <div class="col align-self-end">
                                            <h3
                                              style="
                                                font-weight: 700;
                                                font-size: 28px;
                                                color: rgb(84, 143, 77);
                                              "
                                              class="float-right"
                                            >
                                              £
                                              {{
                                                (excludingVat() / 1.2).toFixed(
                                                  2
                                                )
                                              }}
                                            </h3>
                                          </div>
                                        </div>
                                        <div class="row mb-3 mt-3">
                                          <p
                                            style="
                                              font-weight: 500;
                                              color: black;
                                            "
                                            class="
                                              col-md-6
                                              mb-0
                                              text-left
                                              res-ww-50
                                            "
                                          >
                                            VAT (20%):
                                          </p>
                                          <p
                                            style="
                                              color: rgb(84, 143, 77);
                                              font-weight: 700;
                                              font-size: 20px;
                                            "
                                            class="
                                              col-md-6
                                              mb-0
                                              text-right
                                              res-ww-50
                                            "
                                          >
                                            £
                                            {{ vatPercentage() }}
                                          </p>
                                        </div>
                                        <div class="row mb-2">
                                          <div class="col align-self-end">
                                            <h6
                                              style="
                                                font-weight: bold;
                                                color: black;
                                              "
                                              class="text-uppercase float-right"
                                            >
                                              Total Payment (Incl. Vat)
                                            </h6>
                                          </div>
                                        </div>
                                        <div class="row mb-3">
                                          <div class="col align-self-end">
                                            <h3
                                              style="
                                                font-weight: 700;
                                                font-size: 28px;
                                                color: rgb(84, 143, 77);
                                              "
                                              class="float-right"
                                            >
                                              £{{ includingVat() }}
                                            </h3>
                                          </div>
                                        </div>
                                        <div
                                          v-if="totalDiscountPercentage != 100"
                                        >
                                          <div class="row">
                                            <div class="col-md-12">
                                              <card
                                                class="stripe-card"
                                                :class="{ complete }"
                                                stripe="pk_test_T5AXnYAWQSypZOkdH68vgD8y0010oyGwce"
                                                :options="stripeOptions"
                                                @change="
                                                  complete = $event.complete;
                                                  stripeErrorMessage = '';
                                                "
                                              />
                                              <p class="error float-left">
                                                {{ stripeErrorMessage }}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          v-if="
                                            optionalExtra['forSaleSignPrice']
                                          "
                                          style="font-weight: 600; color: black"
                                          class="text-left mt-3"
                                        >
                                          <input
                                            v-model="customBillingAddress"
                                            style="
                                              transform: scale(1.5);
                                              margin-left: 4px;
                                            "
                                            class="mr-2"
                                            type="checkbox"
                                          />Billing address different from
                                          listing
                                        </div>
                                        <div
                                          style="padding: 0px"
                                          v-if="
                                            optionalExtra['forSaleSignPrice'] &&
                                            customBillingAddress
                                          "
                                          class="property-form-group"
                                        >
                                          <div class="row">
                                            <div
                                              class="form-check text-left mt-2"
                                            >
                                              <label
                                                style="
                                                  margin-left: -6px !important;
                                                  font-size: 17px;
                                                  margin-top: 15px !important;
                                                "
                                                class="form-check-label"
                                                for="defaultCheck1"
                                              >
                                                Delivery address for Sale Sign
                                              </label>
                                            </div>
                                            <div
                                              v-if="
                                                addressType == 'manual' ||
                                                switchTo
                                              "
                                              class="col-lg-12 col-md-12"
                                            >
                                              <p class="text-left">
                                                <label
                                                  for="address"
                                                  style="
                                                    margin-top: 15px !important;
                                                  "
                                                  >House No.</label
                                                >
                                                <input
                                                  @click="inFocus(false)"
                                                  :class="
                                                    $v.houseNo.$dirty &&
                                                    !$v.houseNo.required
                                                      ? 'error-border'
                                                      : ''
                                                  "
                                                  v-model="houseNo"
                                                  type="text"
                                                  name="houseNo"
                                                  placeholder="House Number or Name"
                                                  id="houseNo"
                                                />
                                              </p>
                                              <p
                                                class="error mt-n4"
                                                v-if="
                                                  $v.houseNo.$dirty &&
                                                  !$v.houseNo.required
                                                "
                                              >
                                                This field is required!
                                              </p>
                                            </div>
                                            <div
                                              v-if="
                                                addressType == 'manual' ||
                                                switchTo
                                              "
                                              class="col-lg-6 col-md-12"
                                            >
                                              <p class="text-left">
                                                <label for="address"
                                                  >Street</label
                                                >
                                                <input
                                                  @click="inFocus(false)"
                                                  :class="
                                                    $v.street.$dirty &&
                                                    !$v.street.required
                                                      ? 'error-border'
                                                      : ''
                                                  "
                                                  v-model="street"
                                                  type="text"
                                                  name="street"
                                                  placeholder="Enter Your Street"
                                                  id="street"
                                                />
                                              </p>
                                              <p
                                                class="error mt-n4"
                                                v-if="
                                                  $v.street.$dirty &&
                                                  !$v.street.required
                                                "
                                              >
                                                This field is required!
                                              </p>
                                            </div>
                                            <div
                                              v-if="
                                                addressType == 'manual' ||
                                                switchTo
                                              "
                                              class="col-lg-6 col-md-12"
                                            >
                                              <p class="text-left">
                                                <label for="city">City</label>
                                                <input
                                                  @click="inFocus(false)"
                                                  :class="
                                                    $v.city.$dirty &&
                                                    !$v.city.required
                                                      ? 'error-border'
                                                      : ''
                                                  "
                                                  v-model="city"
                                                  type="text"
                                                  name="city"
                                                  placeholder="Enter Your City"
                                                  id="city"
                                                />
                                              </p>
                                              <p
                                                class="error mt-n4"
                                                v-if="
                                                  $v.city.$dirty &&
                                                  !$v.city.required
                                                "
                                              >
                                                This field is required!
                                              </p>
                                            </div>
                                          </div>
                                          <div class="row">
                                            <div class="col-lg-6 col-md-12">
                                              <p class="text-left">
                                                <label for="state"
                                                  >Post Code</label
                                                >
                                                <input
                                                  @click="inFocus(false)"
                                                  :class="
                                                    $v.postCode.$dirty &&
                                                    !$v.postCode.required
                                                      ? 'error-border'
                                                      : ''
                                                  "
                                                  v-model="postCode"
                                                  type="text"
                                                  name="postCode"
                                                  placeholder="Postcode"
                                                  id="postCode"
                                                />
                                              </p>
                                              <p
                                                class="error mt-n4"
                                                v-if="
                                                  $v.postCode.$dirty &&
                                                  !$v.postCode.required
                                                "
                                              >
                                                This field is required!
                                              </p>
                                              <select
                                                @click="inFocus(false)"
                                                v-if="addressType == 'lookup'"
                                                v-model="lookupAddress"
                                                :class="[
                                                  lookupError ? '' : 'mb-4',
                                                  'selectfiled arrows',
                                                ]"
                                              >
                                                <option
                                                  selected
                                                  disabled
                                                  value="1"
                                                >
                                                  Select an address
                                                </option>
                                                <option
                                                  @click="inFocus(false)"
                                                  v-for="(
                                                    address, index
                                                  ) in suggestions"
                                                  :key="index"
                                                  :value="address"
                                                >
                                                  {{ address["line_1"] }}
                                                </option>
                                              </select>
                                              <p
                                                class="error"
                                                v-if="lookupError"
                                              >
                                                Invalid Post Code
                                              </p>
                                            </div>
                                            <div
                                              v-if="
                                                addressType == 'manual' ||
                                                switchTo
                                              "
                                              class="col-lg-6 col-md-12"
                                            >
                                              <p class="text-left">
                                                <label for="county"
                                                  >County</label
                                                >
                                                <input
                                                  @click="inFocus(false)"
                                                  v-model="county"
                                                  type="text"
                                                  name="county"
                                                  placeholder="Enter Your County"
                                                  id="county"
                                                />
                                              </p>
                                            </div>
                                          </div>
                                          <div>
                                            <button
                                              style="
                                                background: #272c32 !important;
                                                width: 172px !important;
                                              "
                                              @click="addressType = 'lookup'"
                                              :class="[
                                                'btn',
                                                'float-left',
                                                'btn-yellow',
                                                addressType == 'lookup'
                                                  ? 'listing-button-color'
                                                  : 'listing-button-color-secondary',
                                              ]"
                                            >
                                              Look Up
                                            </button>
                                            <button
                                              style="width: 172px !important"
                                              @click="addressType = 'manual'"
                                              :class="[
                                                'btn',
                                                'float-right',
                                                'btn-yellow',
                                                addressType == 'manual'
                                                  ? 'listing-button-color'
                                                  : 'listing-button-color-secondary',
                                              ]"
                                            >
                                              Enter Manually
                                            </button>
                                          </div>
                                          <div class="row"></div>
                                        </div>

                                        <div
                                          style="margin-top: 50px"
                                          class="row"
                                        >
                                          <div
                                            data-v-a2d10c0c=""
                                            class="col align-self-start"
                                          >
                                            <button
                                              @click="paypalPay"
                                              class="
                                                mt-4
                                                btn btn-paynow
                                                res-button-how
                                                float-left
                                              "
                                              style="
                                                float: left;
                                                padding-right: 30px;
                                                padding-left: 30px;
                                                height: 50px;
                                                padding-top: 12px;
                                                color: white;
                                              "
                                            >
                                              PayPal
                                            </button>
                                          </div>
                                          <div
                                            data-v-a2d10c0c=""
                                            class="col align-self-end"
                                          >
                                            <button
                                              :class="[
                                                'mt-4',
                                                'btn btn-paynow',
                                                'res-button-how',

                                                'float-right',

                                                `${
                                                  !complete
                                                    ? 'hover-disabled'
                                                    : ''
                                                }`,
                                              ]"
                                              @click="pay"
                                              :disabled="
                                                !complete &&
                                                totalDiscountPercentage != 100
                                              "
                                              data-v-a2d10c0c=""
                                              style="
                                                float: right;
                                                padding-right: 30px;
                                                padding-left: 30px;
                                                height: 50px;
                                                padding-top: 12px;
                                                color: white;
                                              "
                                            >
                                              {{
                                                totalDiscountPercentage == 100
                                                  ? "Create Listing"
                                                  : "Pay Now"
                                              }}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <br />
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div v-else-if="packages.length">
            <div class="container">
              <div
                style="margin-top: 75px; margin-bottom: 75px"
                class="col-md-4 offset-md-4 text-center"
              >
                <img
                  style="
           
            align-self: center;
            <!-- left: 42%; -->
            position: relative;
          "
                  class="loading-image text-center"
                  :src="require('../assets/images/payment.png')"
                />
                <h1
                  style="
                    color: rgb(84, 143, 77);
                    text-align: center;
                    font-size: 28px;
                    margin-top: 15px;
                    font-weight: 600;
                  "
                >
                  Payment Successful
                </h1>
                <div>
                  <p style="color: black" class="paymentstyle">
                    We have received your payment of £{{
                      parseFloat(this.includingVat()).toFixed(2)
                    }}, now sit back and relax while we find you a buyer.
                  </p>
                </div>
                <div class="text-center">
                  <button
                    style="margin: auto !important"
                    class="btn btn-primary buttonpay res-remocinig-margin"
                    @click="$router.push(`/property-details/${propertyId}`)"
                  >
                    Continue
                  </button>
                </div>
                <div class="text-center mt-2">
                  <p style="color: black">
                    Don't forget to share your listing on social media.
                  </p>
                </div>
                <div class="mt-2">
                  <ShareNetwork
                    network="facebook"
                    :url="`https://vencasa.netlify.app/property-details/${
                      this.$route.query.propertyId
                        ? this.$route.query.propertyId
                        : this.propertyId
                    }`"
                    title="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    description="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    quote="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    hashtags="vencasa"
                  >
                    <i
                      style="color: #548f4d"
                      class="fab fah fa-lg fa-facebook"
                    ></i>
                  </ShareNetwork>
                  <ShareNetwork
                    network="twitter"
                    :url="`https://vencasa.netlify.app/property-details/${
                      this.$route.query.propertyId
                        ? this.$route.query.propertyId
                        : this.propertyId
                    }`"
                    title="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    description="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    quote="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    hashtags="vencasa"
                  >
                    <i
                      style="color: #548f4d"
                      class="fab fah fa-lg fa-twitter"
                    ></i>
                  </ShareNetwork>
                  <ShareNetwork
                    network="linkedin"
                    :url="`https://vencasa.netlify.app/property-details/${
                      this.$route.query.propertyId
                        ? this.$route.query.propertyId
                        : this.propertyId
                    }`"
                    title="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    description="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    quote="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    hashtags="vencasa"
                  >
                    <i
                      style="color: #548f4d"
                      class="fab fah fa-lg fa-linkedin-in"
                    ></i>
                  </ShareNetwork>
                  <ShareNetwork
                    network="email"
                    :url="`https://vencasa.netlify.app/property-details/${
                      this.$route.query.propertyId
                        ? this.$route.query.propertyId
                        : this.propertyId
                    }`"
                    title="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    description="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    quote="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    hashtags="vencasa"
                  >
                    <i style="color: #548f4d" class="fas fa-lg fa-envelope"></i>
                  </ShareNetwork>
                </div>
              </div>
            </div>
          </div>
        </div>
      </tab-content>
    </form-wizard>
    <b-modal
      v-if="drafted"
      size="lg"
      centered
      hide-header
      hide-footer
      ref="imageBox"
      id="modal-1"
    >
      <div v-if="drafted.floorPlan && shouldShow == 'floorPlan'">
        <div
          class="d-flex justify-content-center"
          :style="`height: ${screenHeight / 1.2}px; width: ${
            screenWidth / 2.4
          }px; background-color: grey`"
        >
          <img class="mb-3" alt="image" :src="`${currentImageUrl}`" />
        </div>
      </div>
      <div
        class="d-flex justify-content-center"
        v-if="drafted.epcCertificate && shouldShow == 'epcCertificate'"
        :style="`height: ${screenHeight / 1.2}px; width: ${
          screenWidth / 2.4
        }px; background-color: grey`"
      >
        <img
          alt="image"
          :src="
            drafted.epcCertificate
              ? `${drafted.epcCertificate.epcDocument}`
              : ''
          "
        />
      </div>
    </b-modal>
    <b-modal centered ref="paypal-failure-modal" hide-header>
      <center>
        <p class="my-4">Payment failed</p>
      </center>
      <template #modal-footer>
        <b-button
          @click="$refs['paypal-failure-modal'].hide()"
          variant="primary"
          size="md"
          class="listing-button-color btn btn-primary"
        >
          OK
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import $ from "jquery";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
const simpleListProperty = () =>
  import(
    /* webpackChunkName: "EdovG3rxiE" */ "../components/simple-list-property"
  );
import vueDropzone from "vue2-dropzone";
import "../../node_modules/vue2-dropzone/dist/vue2Dropzone.min.css";
import { headers } from "../utils";
import { onlyNumberInput } from "../utils";
import Quill from "quill";
import { capitalize, formatter } from "../utils";
import _ from "lodash";
import { Card, createToken } from "vue-stripe-elements-plus";
import { gmapApi } from "vue2-google-maps";
import Vue from "vue";

export default {
  components: {
    vueDropzone,
    simpleListProperty,
    Card,
  },
  data() {
    return {
      lookupError: false,
      totalDiscountPercentage: 0,
      couponPromiseQueue: [],
      customBillingAddress: false,
      stripeErrorMessage: "",
      showCouponError: false,
      delayCreate: false,
      screenWidth: window.innerWidth,
      lat: 0,
      lng: 0,
      currentImageUrl: "",
      grandTotal: 0,
      optionalExtra: {
        premiumListingPrice: false,
        charitableDonationPrice: false,
        forSaleSignPrice: false,
      },
      removedList: {
        garageDescription: false,
        tenureDescription: false,
        swimmingPoolDescription: false,
        parkingDescription: false,
        gardenDescription: false,
      },
      customRooms: [],
      customRoomDescription: [],
      otherroomDescription: [],
      bathroomDescription: [],
      bedroomDescription: [],
      mainPageDescriptionTitle: "Main Description",
      garageDescriptionTitle: "Garage",
      swimmingPoolDescriptionTitle: "Swimming Pool",
      parkingDescriptionTitle: "Parking",
      gardenDescriptionTitle: "Garden",
      tenureDescriptionTitle: "Tenure",
      stopFromCreatingDraft: false,
      redeemReward: 0,
      referralSlabs: null,
      isChecked: false,
      showRedeem: false,
      enumsDiscountType: {
        value: "£",
        percentage: "%",
      },
      isCodeAdded: false,
      total: 0,
      couponCode: "",
      shouldShow: false,
      property: null,
      subscribedPackage: null,
      pendingIndex: this.$route.query
        ? this.$route.query.pending == "payment"
          ? 1
          : 0
        : 0,
      tokenData: null,
      selectedPack: "",
      propertyId: "",
      stripeOptions: {
        style: {
          base: {
            color: "black",
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            fontSize: "18px",
            fontWeight: "400",
            fontSmoothing: "antialiased",
            "::placeholder": {
              color: "black",
            },
            borderRadius: "20px",
          },
        },
      },
      complete: false,
      screenHeight: window.innerHeight,
      screenWidth: window.innerWidth,
      shouldShow: "",
      orderChanged: false,
      removing: false,
      switching: false,
      drafted: null,
      showLaterMessageFloorPlan: false,
      showLaterMessageImages: false,
      showWarningMessage: false,
      showError: false,
      doNotDraft: true,
      focused: false,
      isGardenChecked: false,
      switchTo: false,
      isDescriptionChanged: false,
      enums: {
        houseNo: "houseNo",
        street: "street",
        city: "city",
        postCode: "postCode",
        county: "county",
        price: "price",
        type: "propertyType",
        bedroom: "noOfBedrooms",
        bathroom: "noOfBathrooms",
        otherroom: "noOfOtherRooms",
        garage: "garage",
        tenure: "tenure",
        swimmingPool: "swimmingPool",
        parkingType: "parkingType",
        noOfCars: "noOfCars",
        garden: "garden",
        onStreet: "On Street",
        offStreet: "Off Street",
        permitted: "Permitted",
        allocated: "Allocated",
      },
      county: "",
      inner: "",
      termsAndConditions: null,
      showRequired: false,
      showSpinner: false,
      mainDescription: "",
      garageDescription: "",
      parkingDescription: "",
      tenureDescription: "",
      mainPageDescription: "",
      swimmingPoolDescription: "",
      gardenDescription: "",
      epcRating: "",
      uploadedImages3: [],
      uploadedImages2: [],
      uploadedImages: [],
      isImageUploaded3: true,
      isImageUploaded2: true,
      isImageUploaded: true,
      imagesAdded: false,
      images: [],
      dropOptionsEpc: {
        transformFile: function (file, done) {
          var reader = new FileReader();

          reader.onload = (e) => {
            file.dataURL = e.target.result;
            done(file);
          };
          reader.readAsDataURL(file);
        },
        accept: function (file, done) {
          setTimeout(() => {
            done();
          }, 1000);
        },
        maxFiles: 1,
        addRemoveLinks: true,
        headers: headers().headers,
        url: `${process.env.VUE_APP_BASE_URL}/api/base64`,
      },
      dropOptions: {
        transformFile: function (file, done) {
          var reader = new FileReader();

          reader.onload = (e) => {
            file.dataURL = e.target.result;
            done(file);
          };
          reader.readAsDataURL(file);
        },
        accept: function (file, done) {
          setTimeout(() => {
            done();
          }, 1000);
        },
        acceptedFiles: "image/jpeg,image/png,.mp4,.mkv,.avi",
        addRemoveLinks: true,
        headers: headers().headers,
        url: `${process.env.VUE_APP_BASE_URL}/api/base64`,
      },
      dropOptionsFloorPlan: {
        transformFile: function (file, done) {
          var reader = new FileReader();

          reader.onload = (e) => {
            file.dataURL = e.target.result;
            done(file);
          };
          reader.readAsDataURL(file);
        },
        accept: function (file, done) {
          setTimeout(() => {
            done();
          }, 1000);
        },
        addRemoveLinks: true,
        headers: headers().headers,
        url: `${process.env.VUE_APP_BASE_URL}/api/base64`,
      },
      fileToBeRemoved: null,
      epcFile: [],
      floorPlan: [],
      floorPlans: null,
      epcDescription: "",
      file1: null,
      addressType: "lookup",
      propertyTitle: "",
      houseNo: "",
      propertyDescription: "",
      status: "",
      type: "",
      rooms: "",
      price: "",
      area: "",
      street: "",
      city: "",
      postCode: "",
      country: "UK",
      bedroom: [],
      bathroom: [],
      otherroom: [],
      features: [],
      basement: "",
      garage: "",
      swimmingPool: "",
      kitchen: "",
      parking: "",
      noOfCars: "",
      parkingType: "",
      garden: "",
      gardenArea: "sqMeters",
      name: "",
      username: "",
      email: "",
      phone: "",
      tenure: "free",
      totalDescription: [],
      customLabel: "",
      customLabelHolder: [],
      convertedDescription: [],
      adjustmentForTerms: "Next",
      lookupAddress: "1",
    };
  },
  computed: {
    google: gmapApi,
    _() {
      return _;
    },
    ...mapGetters({
      suggestions: "getSuggestions",
      authUser: "auth",
      propertyDraft: "getCurrentDraft",
      startIndex: "getIndex",
      configurations: "getConfigurations",
      packages: "getPackages",
      properties: "getUserProperties",
      couponValue: "getCouponByVal",
      buyerSeller: "getBuyerSeller",
    }),
  },
  watch: {
    isChecked(val) {
      if (!val) {
        this.grandTotal =
          this.grandTotal + parseFloat(this.referralSlabs.val.slab1.reward);
        return;
      }
      this.redeemReward = this.referralSlabs.val.slab1.reward;
      this.grandTotal = this.grandTotal - this.redeemReward;
    },
    buyerSeller(val) {
      this.$store
        .dispatch("getConfigurationsByKey", "referralSlabs")
        .then((res) => {
          if (res.data.success) {
            this.referralSlabs = res.data.data;
            this.showRedeem =
              val.referralPoints >= this.referralSlabs.val.slab1.range;
          }
        });
    },
    property(val) {
      if (!val) return;
    },
    couponValue(val) {
      this.totalDiscountPercentage = 0;
      if (!val) return;
      if (this.isCodeAdded) {
        this.grandTotal = 0;
        this.grandTotal = this.total;
        if (this.isChecked)
          this.grandTotal = this.grandTotal - this.redeemReward;
      }
      if (val.discountType == "percentage") {
        this.grandTotal =
          this.grandTotal - (this.grandTotal * val.discount) / 100;
      } else {
        this.grandTotal = this.grandTotal - val.discount;
      }
      this.totalDiscountPercentage =
        (this.couponValue.discount * 100) /
        (this.grandTotal + this.couponValue.discount);
    },
    couponCode(newVal, oldVal) {
      this.showCouponError = false;
      for (let i = 0; i < this.couponPromiseQueue.length; i++) {
        clearTimeout(this.couponPromiseQueue[i]);
      }
      this.couponPromiseQueue.splice(0, this.couponPromiseQueue.length);
      this.couponPromiseQueue = [
        ...this.couponPromiseQueue,
        setTimeout(() => {
          if (newVal.length > oldVal.length) {
            this.$store
              .dispatch("getByCouponCode", { code: newVal })
              .then((res) => {
                if (res.data.success) {
                  this.isCodeAdded = true;
                } else this.showCouponError = true;
              });
          } else {
            this.$store
              .dispatch("getByCouponCode", { code: newVal })
              .then((res) => {
                if (res.data.success) {
                  this.isCodeAdded = true;
                } else {
                  this.showCouponError = false;
                  this.isCodeAdded = false;
                  this.$store.commit("setCouponByVal", null);
                  this.grandTotal = 0;
                  this.grandTotal = this.total;
                  if (this.isChecked)
                    this.grandTotal = this.grandTotal - this.redeemReward;
                }
              });
          }
        }, 2000),
      ];
    },
    propertyDraft(val) {
      this.drafted = val ? JSON.parse(val.property) : null;
    },
    totalDescription(val) {
      if (val.length == 2 && !this.removing) {
      } else {
        this.removing = false;
        this.convertObject();
        if (!this.stopFromCreatingDraft) this.createDraft();
      }
    },
    isGardenChecked(val) {
      if (!val) this.garden = "";
    },
    lookupAddress(val) {
      this.street = val["thoroughfare"];
      this.city = val["town_or_city"];
      this.county = val["county"];
      this.addressType = "lookup";
      this.houseNo =
        val["building_name"] ||
        val["building_number"] ||
        val["sub_building_name"] ||
        val["line_1"];
      this.switchTo = true;
    },
    google() {
      var geocoder = new this.google.maps.Geocoder();
      geocoder.geocode(
        { address: this.formattedPostCode(this.postCode.toUpperCase().trim()) },
        (results, status) => {
          this.lat = results[0].geometry.location.lat();
          this.lng = results[0].geometry.location.lng();
        }
      );
    },
    postCode(val) {
      this.lookupError = false;
      if (val.length >= 3)
        this.$store
          .dispatch("addressLookup", {
            postCode: val.trim(),
            houseNo: "",
          })
          .then((res) => {
            if (res !== true) this.lookupError = true;
          });
      if (!this.google) return;
      var geocoder = new this.google.maps.Geocoder();
      geocoder.geocode(
        { address: this.formattedPostCode(this.postCode.toUpperCase().trim()) },
        (results, status) => {
          this.lat = results[0].geometry.location.lat();
          this.lng = results[0].geometry.location.lng();
        }
      );
    },
  },
  validations: {
    tenure: {
      required,
    },
    epcDescription: {
      required,
    },
    epcFile: {
      required,
    },
    propertyTitle: {
      required,
    },
    propertyDescription: {
      required,
    },
    status: {
      required,
    },
    type: {
      required,
    },
    rooms: {
      required,
    },
    price: {
      required,
    },
    area: {
      required,
    },
    houseNo: {
      required,
    },
    street: {
      required,
    },
    city: {
      required,
    },
    postCode: {
      required,
    },
    lat: {
      required,
    },
    lng: {
      required,
    },
    name: {
      required,
    },
    username: {
      required,
    },
    email: {
      required,
    },
    phone: {
      required,
    },
    addressType: {
      required,
    },
    tenure: {
      required,
    },
    floorPlan: {
      required,
    },
    epcRating: {
      required,
    },
    mainDescription: {
      required,
    },
    images: {
      required,
    },
  },
  methods: {
    vatPercentage() {
      return ((this.excludingVat() / 1.2).toFixed(2) * 0.2).toFixed(2);
    },
    theOne() {
      let calcString = `${parseFloat(this.packages[0].price).toFixed(2)}${
        this.optionalExtra["charitableDonationPrice"]
          ? "+" +
            parseFloat(this.packages[0].charitableDonationPrice).toFixed(2)
          : ""
      }${
        this.optionalExtra["forSaleSignPrice"]
          ? "+" + parseFloat(this.packages[0].forSaleSignPrice).toFixed(2)
          : ""
      }${
        this.optionalExtra["premiumListingPrice"]
          ? "+" + parseFloat(this.packages[0].premiumListingPrice).toFixed(2)
          : ""
      }`;
      return eval(calcString);
    },
    excludingVat() {
      let calcString = `${parseFloat(this.grandTotal).toFixed(2)}`;
      return eval(calcString);
    },
    includingVat() {
      return (
        parseFloat((this.excludingVat() / 1.2).toFixed(2)) +
        parseFloat(((this.excludingVat() / 1.2).toFixed(2) * 0.2).toFixed(2))
      ).toFixed(2);
    },
    runCreatePayment(data, fullDiscount) {
      this.tokenData = data;
      const stripeData = {
        paymentMethod: "stripe",
        tokenId: this.tokenData.token.id,
        cardId: this.tokenData.token.card.id,
        amount: this.includingVat(),
        vat: this.vatPercentage(),
        discount:
          Object.keys(_.get(this.couponValue, "discountType", {})).length > 0
            ? this.couponValue.discountType == "value"
              ? this.couponValue.discount
              : ((parseFloat(this.includingVat()) /
                  ((100 - this.couponValue.discount) / 100)) *
                  this.couponValue.discount) /
                100
            : 0,
      };
      if (this.includingVat() == 0) {
        stripeData.discount =
          parseFloat(this.packages[0].price) +
          parseFloat(this.packages[0].price) * 0.2;
      }
      if (this.isChecked) {
        this.$store.dispatch("redeemPoints").then(async (res) => {
          if (res == true) {
            this.$store
              .dispatch("getBuyerSeller", this.buyerSeller.id)
              .then((res) => {
                if (res.data.success) {
                  this.createPayment(stripeData, fullDiscount);
                } else alert("error in fetching buyer_seller");
              });
          }
        });
      } else this.createPayment(stripeData, fullDiscount);
    },
    addPhoto() {
      document.querySelectorAll(".vue-dropzone")[0].click();
    },
    addPhotoFloorPlan() {
      document.querySelectorAll(".vue-dropzone")[1].click();
    },
    addPhotoEpc() {
      document.querySelectorAll(".vue-dropzone")[2].click();
    },
    setDescriptionTitle() {
      this.tenureDescriptionTitle =
        "Tenure: " + this.capitalize(this.tenure) + "hold";
    },
    async paypalPay() {
      this.$store.commit("setShowLoader", true);
      const data = {
        propertyId: this.propertyId,
        packageId: this.packages[0].id,
        isPremiumListing: this.optionalExtra["premiumListingPrice"] ? 1 : 0,
        isForSaleSign: this.optionalExtra["forSaleSignPrice"] ? 1 : 0,
        forSaleSignAddress: this.optionalExtra["forSaleSignPrice"]
          ? `${this.houseNo}, ${this.street}, ${this.city}, ${this.postCode}${
              this.county ? `,${this.county}` : ""
            }`
          : "",
        isCharitableDonation: this.optionalExtra["charitableDonationPrice"]
          ? 1
          : 0,
      };
      await this.$store
        .dispatch("packageSubscription", data)
        .then(async (res) => {
          if (res.success) {
            this.subscribedPackage = res.data;
            const paypalData = {
              name: this.buyerSeller.name,
              propertyId: this.propertyId,
              paymentMethod: "paypal",
              amount: this.includingVat(),
              vat: this.vatPercentage(),
              discount:
                Object.keys(_.get(this.couponValue, "discountType", {}))
                  .length > 0
                  ? this.couponValue.discountType == "value"
                    ? this.couponValue.discount
                    : ((parseFloat(this.includingVat()) /
                        ((100 - this.couponValue.discount) / 100)) *
                        this.couponValue.discount) /
                      100
                  : 0,
              packageId: this.subscribedPackage.id,
            };
            this.$store
              .dispatch("createStripePayment", paypalData)
              .then((res) => {
                this.$store.commit("setShowLoader", false);
                if (res.status == 200) {
                  window.location.href = res.data.data.redirectUrl;
                } else alert("error in paypal payment");
              });
          }
        });
    },
    decrypt(key) {
      return this.$store.dispatch("decrypt", { key: key }).then((res) => {
        if (res.status == 200) return _.get(res, "data.data.decrypted", false);
        else return false;
      });
    },
    formattedPostCode(postCode) {
      let formattedPostCode = "";
      let arr = [];
      if (postCode.length == 5) {
        arr[0] = postCode.substr(0, 2);
        arr[1] = " ";
        arr[2] = postCode.substr(2);
        formattedPostCode = arr[0] + arr[1] + arr[2];
      } else if (postCode.length == 6) {
        arr[0] = postCode.substr(0, 3);
        arr[1] = " ";
        arr[2] = postCode.substr(3);
        formattedPostCode = arr[0] + arr[1] + arr[2];
      } else if (postCode.length == 7) {
        arr[0] = postCode.substr(0, 4);
        arr[1] = " ";
        arr[2] = postCode.substr(4);
        formattedPostCode = arr[0] + arr[1] + arr[2];
      } else formattedPostCode = postCode;
      return formattedPostCode;
    },
    removeBedBathOther(label, title, order) {
      if (label == "bedroom") {
        let sliced = this.bedroomDescription.filter((desc) => {
          return Object.keys(desc)[0] !== title;
        });
        this.bedroomDescription = [];
        let sliced2 = this.bedroom.filter((room) => room.order !== order);
        this.bedroom = [];
        for (let i = 0; i < sliced.length; i++)
          this.$set(this.bedroomDescription, `${i}`, sliced[i]);
        for (let i = 0; i < sliced2.length; i++)
          this.$set(this.bedroom, `${i}`, sliced2[i]);
      } else if (label == "bathroom") {
        let sliced = this.bathroomDescription.filter((desc) => {
          return Object.keys(desc)[0] !== title;
        });
        this.bathroomDescription = [];
        let sliced2 = this.bathroom.filter((room) => room.order !== order);
        this.bathroom = [];
        for (let i = 0; i < sliced.length; i++)
          this.$set(this.bathroomDescription, `${i}`, sliced[i]);
        for (let i = 0; i < sliced2.length; i++)
          this.$set(this.bathroom, `${i}`, sliced2[i]);
      } else if (label == "otherroom") {
        let sliced = this.otherroomDescription.filter((desc) => {
          return Object.keys(desc)[0] !== title;
        });
        this.otherroomDescription = [];
        let sliced2 = this.otherroom.filter((room) => room.order !== order);
        this.otherroom = [];
        for (let i = 0; i < sliced.length; i++)
          this.$set(this.otherroomDescription, `${i}`, sliced[i]);
        for (let i = 0; i < sliced2.length; i++)
          this.$set(this.otherroom, `${i}`, sliced2[i]);
      }
    },
    removeOtherThanBathBedOther(label) {
      this.$set(this.removedList, label, true);
    },
    convertToInt(value) {
      return parseInt(value) || 0;
    },
    formatter: formatter,
    orderedCustomBoxes(num) {
      let arr = [];
      for (let i = 1; i <= num; i++) {
        arr = [...arr, i];
      }
      return arr.map((a) => {
        return {
          order: a,
        };
      });
    },
    findBedroomDescription(order) {
      let found = this.bedroomDescription.find(
        (desc) => desc[Object.keys(desc)[0]].order == order
      );
      return found ? found[Object.keys(found)[0]] : {};
    },
    findBathroomDescription(order) {
      let found = this.bathroomDescription.find(
        (desc) => desc[Object.keys(desc)[0]].order == order
      );
      return found ? found[Object.keys(found)[0]] : {};
    },
    findOtherroomDescription(order) {
      let found = this.otherroomDescription.find(
        (desc) => desc[Object.keys(desc)[0]].order == order
      );
      return found ? found[Object.keys(found)[0]] : {};
    },
    findCustomroomDescription(order) {
      let found = this.customRoomDescription.find(
        (desc) => desc[Object.keys(desc)[0]].order == order
      );
      if (found) return found[Object.keys(found)[0]];
      else return {};
    },
    alignPayment() {
      if ($(window).width() <= 700) {
        $(".payment").removeClass("ml-3");
        $(".payment").addClass("text-center");
        $(".res-div").removeClass("col-md-5");
        $(".res-div").removeClass("col-md-7");
      } else if ($(window).width() > 700) {
        $(".payment").removeClass("text-center");
        $(".payment").addClass("ml-3");
        $(".res-div").addClass("col-md-5");
        $(".res-div").addClass("col-md-7");
      }
    },
    checkLength(event) {
      if (event.target.value.length > 7) event.preventDefault();
    },
    addOptionalExtra(label) {
      this.optionalExtra[label] = !this.optionalExtra[label];
      if (this.optionalExtra[label]) {
        this.total =
          parseFloat(this.total) + parseFloat(this.packages[0][label]);
        this.grandTotal =
          parseFloat(this.grandTotal) + parseFloat(this.packages[0][label]);
      } else {
        this.total =
          parseFloat(this.total) - parseFloat(this.packages[0][label]);
        this.grandTotal =
          parseFloat(this.grandTotal) - parseFloat(this.packages[0][label]);
      }
    },
    async createPayment(stripeData, fullDiscount) {
      const data = {
        propertyId: this.propertyId,
        packageId: this.packages[0].id,
        isPremiumListing: this.optionalExtra["premiumListingPrice"] ? 1 : 0,
        isForSaleSign: this.optionalExtra["forSaleSignPrice"] ? 1 : 0,
        forSaleSignAddress: this.optionalExtra["forSaleSignPrice"]
          ? `${this.houseNo}, ${this.street}, ${this.city}, ${this.postCode}${
              this.county ? `,${this.county}` : ""
            }`
          : "",
        isCharitableDonation: this.optionalExtra["charitableDonationPrice"]
          ? 1
          : 0,
      };
      await this.$store
        .dispatch("packageSubscription", data)
        .then(async (res) => {
          if (res.success) {
            this.subscribedPackage = res.data;
            await this.$store
              .dispatch("createPayment", {
                ...stripeData,
                name: this.buyerSeller.name,
                propertyId: this.propertyId,
                packageId: this.subscribedPackage.id,
                fullDiscount: fullDiscount,
              })
              .then(async (res) => {
                if (res == true) {
                  Vue.analytics.fbq.event("Purchase", {
                    currency: "GBP",
                    value: parseFloat(this.includingVat()),
                  });
                  let runEcologi = false;
                  if (this.couponValue.discountType == "percentage") {
                    runEcologi = this.couponValue.discount < 80;
                  } else if (this.couponValue.discountType == "value") {
                    runEcologi =
                      (this.couponValue.discount * 100) /
                        (parseFloat(this.includingVat()) +
                          this.couponValue.discount) <
                      80;
                  }
                  if (runEcologi)
                    await this.$store.dispatch("ecologi", {
                      number: 16,
                      name: "Vencasa",
                      test: true,
                    });
                  this.$store.commit("setShowLoader", false);
                  document
                    .querySelector("#header")
                    .scrollIntoView({ behavior: "smooth" });
                  this.shouldShow = !this.shouldShow;
                  this.$store
                    .dispatch(
                      "changePackageStatus",
                      this.subscribedPackage
                        ? this.subscribedPackage.id
                        : _.get(
                            this.property.propertyPackage,
                            `[${
                              _.defaultTo(
                                _.get(
                                  this.property.propertyPackage,
                                  "length",
                                  null
                                ),
                                1
                              ) - 1
                            }].id`,
                            false
                          )
                    )
                    .then((res) => {
                      if (res == true) {
                        this.$store
                          .dispatch("publish", this.propertyId)
                          .then((res) => {
                            if (res == true) {
                              this.$store.dispatch("getUserProperties");
                            } else {
                              alert("Error in publishing");
                            }
                          });
                      } else {
                        alert("error in status change");
                      }
                    });
                } else {
                  this.$store.commit("setShowLoader", false);
                  this.stripeErrorMessage = _.get(
                    res,
                    "data.message",
                    "An error has occurred."
                  );
                }
              });
          }
        });
    },
    async pay() {
      this.$store.commit("setShowLoader", true);
      if (this.totalDiscountPercentage != 100)
        await createToken()
          .then(async (data) => {
            this.runCreatePayment(data, 0);
          })
          .catch((err) => {
            this.$store.commit("setShowLoader", false);
          });
      else
        this.runCreatePayment({ token: { id: null, card: { id: null } } }, 1);
    },
    enlargeImage(comp, image = "") {
      this.currentImageUrl = image;
      this.shouldShow = comp;
      this.$refs.imageBox.show();
    },
    addCommas(event) {
      this.price = this.formatter(
        event.target.value.toString().replace(/,/g, "")
      );
    },
    checkIfCustomBox(label) {
      return (
        !label.includes("bedroom") &&
        !label.includes("bathroom") &&
        !label.includes("otherroom")
      );
    },
    getExtension(str) {
      var re = /(?:\.([^.]+))?$/;
      return re.exec(str);
    },
    onError(file, message, xhr) {
      this.$refs.dropzone.removeFile(file);
      this.showError = true;
    },
    scrollToMain() {
      document
        .querySelector(".main-description")
        .scrollIntoView({ behavior: "smooth" });
    },
    sliceString(str) {
      if (str) {
        if (str.length > 280) {
          this.shouldShow = true;
          return str.slice(0, 280);
        } else return str;
      } else return "";
    },
    inFocus(fromDescription) {
      if (fromDescription) this.convertObject();
      this.doNotDraft = false;
      this.focused = true;
    },
    isCustomLabel(description) {
      return (
        description.label != "bedroom" &&
        description.label != "bathroom" &&
        description.label != "otherroom"
      );
    },
    removeDiv() {
      if ($(window).width() <= 700) {
        $(".remove").removeClass("mt-5");
        $(".remove").removeClass("m5");
      } else if ($(window).width() > 700) {
        $(".remove").addClass("mt-5");
        $(".remove").addClass("m5");
      }
    },
    detectChange(items) {
      let check = true;
      for (let i = 0; i < items.length; i++) {
        if (items[i] == "epcCertificate") {
          if (this.drafted.epcCertificate) {
            if (this.epcRating != this.drafted.epcCertificate.epcRating)
              check = false;
            this.epcFile.forEach((image) => {
              if (!this.drafted.epcCertificate.epcDocument.includes(image))
                check = false;
            });
          } else check = false;
        } else if (items[i] == "floorPlan") {
          this[items[i]].forEach((image) => {
            if (!this.drafted.floorPlan.includes(image)) check = false;
          });
        } else if (items[i] == "images") {
          this[items[i]].forEach((image) => {
            if (!this.drafted.images.includes(image)) check = false;
          });
        } else {
          if (
            (items[i] == "swimmingPool" ||
              items[i] == "parking" ||
              items[i] == "garden") &&
            check
          ) {
            check = this[items[i]] == this.drafted[this.enums[items[i]]];
            continue;
          }
          if (this[items[i]] != this.drafted[this.enums[items[i]]]) {
            check = false;
          }
        }
      }
      return check;
    },
    capitalize: capitalize,
    dropping() {
      if (this.fileToBeRemoved)
        this.$refs.dropzone3.removeFile(this.fileToBeRemoved);
    },
    inputLimitClipboard() {
      this.mainDescription = this.mainDescription.slice(0, 250);
    },
    sliceString(str) {
      if (str) {
        if (str.length > 394) {
          this.shouldShow = true;
          return str.slice(0, 385);
        } else return str;
      } else return "";
    },
    inputLimit(event) {
      if (this.mainDescription.length > 250) event.preventDefault();
    },
    checkMainDesc(event) {},
    inputCheck(event) {
      onlyNumberInput(event);
    },
    back() {
      this.$refs.prevButton.$el.click();
    },
    nextForDescription() {
      this.removeEmptyBoxes();
      document
        .querySelector(".formWizard")
        .scrollIntoView({ behavior: "smooth" });
      this.$refs.nextButton.$el.click();
    },
    next() {
      this.removeEmptyBoxes();
      document
        .querySelector(".formWizard")
        .scrollIntoView({ behavior: "smooth" });
      this.$refs.nextButton.$el.click();
    },
    addEpcCertificate(event) {
      let epcFile = event.target.files[0];
      var fileReader = new FileReader();
      var base64;
      fileReader.onload = (fileLoadedEvent) => {
        base64 = fileLoadedEvent.target.result;
        this.$store.dispatch("postImage", { image: base64 }).then((res) => {
          this.epcFile = res.data.data;
        });
      };
      fileReader.readAsDataURL(epcFile);
    },
    fileRemoved3(file, error, xhr) {
      if (this.uploadedImages3.length) {
        let removedImage = _.find(this.uploadedImages3, (image) => {
          return image.id == file.upload.uuid;
        });
        this.epcFile = _.filter(this.epcFile, (image) => {
          return image !== removedImage.url;
        });
        this.uploadedImages3 = _.filter(this.uploadedImages3, (image) => {
          return image.url !== removedImage.url;
        });
      } else {
        this.epcFile.forEach((image, index) => {
          if (image == file.url) {
            this.epcFile.splice(index, 1);
          }
        });
      }
    },
    fileRemoved2(file, error, xhr) {
      if (this.uploadedImages2.length) {
        let removedImage = _.find(this.uploadedImages2, (image) => {
          return image.id == file.upload.uuid;
        });
        this.floorPlan = _.filter(this.floorPlan, (image) => {
          return image !== removedImage.url;
        });
        this.uploadedImages2 = _.filter(this.uploadedImages2, (image) => {
          return image.url !== removedImage.url;
        });
      } else {
        this.floorPlan.forEach((image, index) => {
          if (image == file.url) {
            this.floorPlan.splice(index, 1);
          }
        });
      }
    },
    fileRemoved(file, error, xhr) {
      if (this.uploadedImages.length) {
        let removedImage = _.find(this.uploadedImages, (image) => {
          return image.id == file.upload?.uuid;
        });
        this.images = _.filter(this.images, (image) => {
          return image !== removedImage?.url;
        });
        this.uploadedImages = _.filter(this.uploadedImages, (image) => {
          return image.url !== removedImage?.url;
        });
      } else {
        this.images.forEach((image, index) => {
          if (image == file.url) {
            this.images.splice(index, 1);
          }
        });
      }
    },
    dropzoneMountedEpc() {
      this.$nextTick(() => {
        if (this.epcFile) {
          this.epcFile.forEach((image, index) => {
            var file = {
              size: 123,
              name: `${index}`,
              type: "image/png",
              url: image,
            };
            this.$refs.dropzone3.manuallyAddFile(file, `${image}`);
          });
        }
      });
    },
    dropzoneMountedFloorPlan() {
      this.$nextTick(() => {
        if (this.floorPlan) {
          this.floorPlan.forEach((image, index) => {
            var file = {
              size: 123,
              name: `${index}`,
              type: "image/png",
              url: image,
            };
            this.$refs.dropzone2.manuallyAddFile(file, `${image}`);
          });
        }
      });
    },
    dropzoneMounted() {
      document.querySelector("#drop1").classList.add("sortable");
      $(".sortable").sortable({
        update: () => {
          let orderChangeImages = [...this.images];
          for (
            let i = 0;
            i < document.querySelectorAll(".dz-image img").length;
            i++
          ) {
            let currentIndex =
              document.querySelectorAll(".dz-image")[i].dataset.imageId;
            orderChangeImages[i] = this.images[currentIndex - 1];
          }
          this.images.splice(0, this.images.length, ...orderChangeImages);
          this.orderChanged = true;
        },
      });
      this.$nextTick(() => {
        this.images.forEach((image, index) => {
          var file = {
            size: 123,
            name: `${index}`,
            type: "image/png",
            url: image,
          };
          this.$refs.dropzone.manuallyAddFile(file, `${image}`);
          document.querySelectorAll(".dz-image")[index].dataset.imageId =
            index + 1;
        });
      });
    },
    imageUploadComplete3(file, response) {
      this.fileToBeRemoved = file;
      this.epcFile = [response.data];
      this.isImageUploaded3 = true;
      this.uploadedImages3 = [{ id: file.upload.uuid, url: response.data }];
    },
    sendImages3(file, xhr, formData) {
      this.isImageUploaded3 = false;
      formData.append("image", file.dataURL);
    },
    imageUploadComplete2(file, response) {
      this.floorPlan = [...this.floorPlan, response.data];
      this.isImageUploaded2 = true;
      this.uploadedImages2 = [
        ...this.uploadedImages2,
        { id: file.upload.uuid, url: response.data },
      ];
    },
    sendImages2(file, xhr, formData) {
      this.isImageUploaded2 = false;
      formData.append("image", file.dataURL);
    },
    imageUploadComplete(file, response) {
      $(".sortable").sortable("enable");
      this.images = [...this.images, response.data];
      this.$nextTick(() => {
        document.querySelectorAll(".dz-image")[
          this.images.length - 1
        ].dataset.imageId = this.images.length;
      });
      this.isImageUploaded = true;
      this.uploadedImages = [
        ...this.uploadedImages,
        { id: file.upload.uuid, url: response.data },
      ];
    },
    sendImages(file, xhr, formData) {
      this.isImageUploaded = false;
      formData.append("image", file.dataURL);
      $(".sortable").sortable("disable");
    },
    async checkImage() {
      this.showLaterMessageFloorPlan = false;
      if (this.isImageUploaded2 && this.floorPlan.length > 0) {
        if (this.detectChange(["floorPlan"])) return true;
        return await this.createDraft();
      } else return true;
    },
    async checkEpc() {
      this.imagesAdded = true;
      if (this.epcFile.length > 0 || this.epcRating) {
        if (this.detectChange(["epcCertificate"])) return true;
        return await this.createDraft();
      } else return true;
    },
    async checkDescription() {
      let check = true;
      if (this.$v.mainDescription.$invalid || !check) {
        this.$v.mainDescription.$touch();
        this.showRequired = true;
        return false;
      } else {
        this.convertObject();
        if (!this.isDescriptionChanged) return true;
        else {
          this.isDescriptionChanged = false;
          return await this.createDraft();
        }
      }
    },
    convertObject() {
      this.convertedDescription = [];
      this.convertedDescription = JSON.parse(`[
        {
          "value": { "mainDescription": "${this.mainDescription.replace(
            /[\n\r]/g,
            "\\n"
          )}" },
          "label": "main"
        },
        {
          "value": { "${this.mainPageDescriptionTitle.replace(
            /[\n\r]/g,
            "\\n"
          )}": "${this.mainPageDescription.replace(/[\n\r]/g, "\\n")}" },
          "label": "mainSecondary"
        },
        {
          "value": { "${this.tenureDescriptionTitle.replace(
            /[\n\r]/g,
            "\\n"
          )}": "${this.tenureDescription.replace(/[\n\r]/g, "\\n")}" },
          "label": "tenureDescription",
          "removed": "${this.removedList["tenureDescription"]}"
        }]`);
      this.bedroomDescription.forEach((desc) => {
        this.convertedDescription = [
          ...this.convertedDescription,
          JSON.parse(`{
           "value": { "${desc[Object.keys(desc)[0]].key}": "${desc[
            Object.keys(desc)[0]
          ].value.replace(/[\n\r]/g, "\\n")}" },
          "label": "bedroom",
          "order": "${desc[Object.keys(desc)[0]].order}",
          "key": "${desc[Object.keys(desc)[0]].key}"
        }`),
        ];
      });
      this.bathroomDescription.forEach((desc) => {
        this.convertedDescription = [
          ...this.convertedDescription,
          JSON.parse(`{
           "value": { "${desc[Object.keys(desc)[0]].key}": "${desc[
            Object.keys(desc)[0]
          ].value.replace(/[\n\r]/g, "\\n")}" },
          "label": "bathroom",
          "order": "${desc[Object.keys(desc)[0]].order}",
          "key": "${desc[Object.keys(desc)[0]].key}"
        }`),
        ];
      });
      this.otherroomDescription.forEach((desc) => {
        this.convertedDescription = [
          ...this.convertedDescription,
          JSON.parse(`{
           "value": { "${desc[Object.keys(desc)[0]].key}": "${desc[
            Object.keys(desc)[0]
          ].value.replace(/[\n\r]/g, "\\n")}" },
          "label": "otherroom",
          "order": "${desc[Object.keys(desc)[0]].order}",
          "key": "${desc[Object.keys(desc)[0]].key}"
        }`),
        ];
      });
      let joinString = JSON.parse(`[
      {
          "value": { "${this.garageDescriptionTitle.replace(
            /[\n\r]/g,
            "\\n"
          )}": "${this.garageDescription.replace(/[\n\r]/g, "\\n")}" },
          "label": "garageDescription",
          "removed": "${this.removedList["garageDescription"]}"
        },
        {
          "value": { "${this.swimmingPoolDescriptionTitle.replace(
            /[\n\r]/g,
            "\\n"
          )}": "${this.swimmingPoolDescription.replace(/[\n\r]/g, "\\n")}" },
          "label": "swimmingPoolDescription",
          "removed": "${this.removedList["swimmingPoolDescription"]}"
        },
        {
          "value": { "${this.parkingDescriptionTitle.replace(
            /[\n\r]/g,
            "\\n"
          )}": "${this.parkingDescription.replace(/[\n\r]/g, "\\n")}" },
          "label": "parkingDescription",
          "removed": "${this.removedList["parkingDescription"]}"
        },
        {
           "value": { "${this.gardenDescriptionTitle.replace(
             /[\n\r]/g,
             "\\n"
           )}": "${this.gardenDescription.replace(/[\n\r]/g, "\\n")}" },
          "label": "gardenDescription",
          "removed": "${this.removedList["gardenDescription"]}"
        }]`);
      joinString.forEach((desc) => {
        this.convertedDescription = [...this.convertedDescription, desc];
      });
      this.customRoomDescription.forEach((desc) => {
        this.convertedDescription = [
          ...this.convertedDescription,
          JSON.parse(`{
           "value": { "${desc[Object.keys(desc)[0]].key}": "${desc[
            Object.keys(desc)[0]
          ].value.replace(/[\n\r]/g, "\\n")}" },
          "label": "custom",
          "order": "${desc[Object.keys(desc)[0]].order}",
          "key": "${desc[Object.keys(desc)[0]].key}"
        }`),
        ];
      });
    },
    addToDescription(label, count = 0, orderArr) {
      if (label == "bedroom") {
        if (orderArr) {
          this.bedroom = orderArr;
        } else {
          let tempBedroom = [...this.bedroom];
          for (let i = 1; i <= count; i++) {
            tempBedroom = [...tempBedroom, { order: i }];
          }
          this.bedroom = Array.from(
            new Set(tempBedroom.map((room) => room.order))
          ).map((room) => {
            return {
              order: room,
            };
          });
          if (this.bedroom.length > count) {
            this.bedroom = this.bedroom.splice(0, count);
          }
        }
        let temp = [...this.bedroomDescription];
        this.bedroomDescription = [];
        for (let i = 0; i < this.bedroom.length; i++) {
          let found = temp.find((desc) => {
            return desc[Object.keys(desc)[0]].order == i + 1;
          });
          if (found) {
            temp = [...temp, found];
            continue;
          }
          temp = [
            ...temp,
            JSON.parse(`{ "Bedroom ${i + 1}" : {
          "label": "bedroom",
          "order": "${i + 1}",
          "value": "",
          "key": "Bedroom ${i + 1}"
        }
        }`),
          ];
        }
        this.bedroomDescription = temp;
      } else if (label == "bathroom") {
        if (orderArr) {
          this.bathroom = orderArr;
        } else {
          let tempBathroom = [...this.bathroom];
          for (let i = 1; i <= count; i++) {
            tempBathroom = [...tempBathroom, { order: i }];
          }
          this.bathroom = Array.from(
            new Set(tempBathroom.map((room) => room.order))
          ).map((room) => {
            return {
              order: room,
            };
          });
          if (this.bathroom.length > count) {
            this.bathroom = this.bathroom.splice(0, count);
          }
        }
        let temp = [...this.bathroomDescription];
        this.bathroomDescription = [];
        for (let i = 0; i < this.bathroom.length; i++) {
          let found = temp.find((desc) => {
            return desc[Object.keys(desc)[0]].order == i + 1;
          });
          if (found) {
            temp = [...temp, found];
            continue;
          }
          temp = [
            ...temp,
            JSON.parse(`{ "Bathroom ${i + 1}" : {
          "label": "bathroom",
          "order": "${i + 1}",
          "value": "",
          "key": "Bathroom ${i + 1}"
        }
        }`),
          ];
        }
        this.bathroomDescription = temp;
      } else if (label == "otherroom") {
        if (orderArr) {
          this.otherroom = orderArr;
        } else {
          let tempOtherRoom = [...this.otherroom];
          for (let i = 1; i <= count; i++) {
            tempOtherRoom = [...tempOtherRoom, { order: i }];
          }
          this.otherroom = Array.from(
            new Set(tempOtherRoom.map((room) => room.order))
          ).map((room) => {
            return {
              order: room,
            };
          });
          if (this.otherroom.length > count) {
            this.otherroom = this.otherroom.splice(0, count);
          }
        }
        let temp = [...this.otherroomDescription];
        this.otherroomDescription = [];
        for (let i = 0; i < this.otherroom.length; i++) {
          let found = temp.find((desc) => {
            return desc[Object.keys(desc)[0]].order == i + 1;
          });
          if (found) {
            temp = [...temp, found];
            continue;
          }
          temp = [
            ...temp,
            JSON.parse(`{ "Other Room ${i + 1}" : {
          "label": "otherroom",
          "order": "${i + 1}",
          "value": "",
          "key": "Other Room ${i + 1}"
        }
        }`),
          ];
        }
        this.otherroomDescription = temp;
      } else if (label == "custom") {
        this.customRooms = [
          ...this.customRooms,
          { order: this.customRooms.length + 1 },
        ];
        let temp = [...this.customRoomDescription];
        this.customRoomDescription = [];
        for (let i = 0; i < this.customRooms.length; i++) {
          let found = temp.find((desc) => {
            return desc[Object.keys(desc)[0]].order == i + 1;
          });
          if (found) {
            this.customRoomDescription = [...this.customRoomDescription, found];
            continue;
          }
          this.customRoomDescription = [
            ...this.customRoomDescription,
            JSON.parse(`{ "" : {
          "label": "custom",
          "order": "${this.customRooms[i].order}",
          "value": "",
          "key": "Add a title for this room"
        }
        }`),
          ];
        }
      }
    },
    addCustomLabel() {
      this.totalDescription = [
        ...this.totalDescription,
        {
          label: "",
          value: 1,
          isCustom: true,
        },
      ];
    },
    check1() {
      if (
        this.$v.propertyTitle.$invalid ||
        this.$v.propertyDescription.$invalid ||
        this.$v.status.$invalid ||
        this.$v.type.$invalid ||
        this.$v.rooms.$invalid ||
        this.$v.price.$invalid ||
        this.$v.area.$invalid
      ) {
        if (this.$v.propertyTitle.$invalid) this.$v.propertyTitle.$touch();
        if (this.$v.propertyDescription.$invalid)
          this.$v.propertyDescription.$touch();
        if (this.$v.status.$invalid) this.$v.status.$touch();
        if (this.$v.type.$invalid) this.$v.type.$touch();
        if (this.$v.rooms.$invalid) this.$v.rooms.$touch();
        if (this.$v.price.$invalid) this.$v.price.$touch();
        if (this.$v.area.$invalid) this.$v.area.$touch();
        return false;
      }
      return true;
    },
    async check2() {
      if (this.orderChanged) {
        this.orderChanged = false;
        return await this.createDraft();
      }
      this.showLaterMessageImages = false;
      if (this.isImageUploaded && this.images.length > 0) {
        if (this.detectChange(["images"])) return true;
        return await this.createDraft();
      } else return true;
    },
    async check3() {
      if (
        this.$v.houseNo.$invalid ||
        this.$v.street.$invalid ||
        this.$v.city.$invalid ||
        this.$v.postCode.$invalid
      ) {
        if (this.$v.houseNo.$invalid) this.$v.houseNo.$touch();
        if (this.$v.street.$invalid) this.$v.street.$touch();
        if (this.$v.city.$invalid) this.$v.city.$touch();
        if (this.$v.postCode.$invalid) this.$v.postCode.$touch();
        return false;
      } else if (this.propertyDraft) {
        if (
          this.detectChange(["houseNo", "street", "city", "postCode", "county"])
        )
          return true;
        else return await this.createDraft();
      } else return await this.createDraft();
    },
    async check4() {
      this.$v.type.$touch();
      if (
        this.$v.price.$invalid ||
        this.$v.type.$invalid ||
        this.$v.tenure.$invalid
      ) {
        if (this.$v.tenure.$invalid) this.$v.tenure.$touch();
        if (this.$v.type.$invalid) this.$v.type.$touch();
        if (this.$v.price.$invalid) this.$v.price.$touch();
        return false;
      } else {
        if (
          this.detectChange([
            "price",
            "type",
            "bedroom",
            "bathroom",
            "otherroom",
            "garage",
            "tenure",
            "swimmingPool",
            "parkingType",
            "garden",
            "noOfCars",
          ])
        )
          return true;
        else return await this.createDraft();
      }
    },
    check5() {
      if (
        this.$v.name.$invalid ||
        this.$v.username.$invalid ||
        this.$v.email.$invalid ||
        this.$v.phone.$invalid
      ) {
        if (this.$v.name.$invalid) this.$v.name.$touch();
        if (this.$v.username.$invalid) this.$v.username.$touch();
        if (this.$v.email.$invalid) this.$v.email.$touch();
        if (this.$v.phone.$invalid) this.$v.phone.$touch();
        return false;
      }
      return true;
    },
    removeCustomBox(label, order) {
      let sliced = this.customRoomDescription.filter((desc) => {
        return desc[Object.keys(desc)[0]].order !== order;
      });
      this.customRoomDescription = [];
      for (let i = 0; i < sliced.length; i++)
        this.$set(this.customRoomDescription, `${i}`, sliced[i]);
      let notRemoved = this.customRooms.filter((c) => {
        return c.order != order;
      });
      this.customRooms = [];
      for (let i = 0; i < notRemoved.length; i++) {
        this.$set(this.customRooms, `${i}`, notRemoved[i]);
      }
      this.convertedDescription = this.convertedDescription.filter((desc) => {
        return (
          desc.label !== "custom" ||
          (desc.label == "custom" ? desc.order != order : true)
        );
      });
    },
    removeEmptyBoxes() {
      let sliced = this.totalDescription.filter((desc) => {
        return desc.label;
      });
      this.totalDescription = [];
      for (let i = 0; i < sliced.length; i++)
        this.$set(this.totalDescription, `${i}`, sliced[i]);
      this.removing = true;
    },
    async createDraft() {
      if (this.switching) return;
      this.switching = true;
      this.showSpinner = true;
      const data = {
        sellerId: this.authUser.buyer_seller_id,
        addressType: this.addressType,
        description: this.convertedDescription,
        status: this.status,
        propertyType: this.type,
        price: parseInt(this.price.replace(/,/g, "")),
        houseNo: this.houseNo,
        street: this.street,
        city: this.city,
        postCode: this.postCode,
        country: this.country,
        noOfBedrooms: this.bedroom.length,
        noOfBathrooms: this.bathroom.length,
        noOfOtherRooms: this.otherroom.length,
        garage: this.garage,
        swimmingPool: this.swimmingPool,
        parking: this.parkingType,
        noOfCars: this.noOfCars,
        garden: this.garden,
        gardenArea: this.gardenArea,
        images: this.images,
        epcCertificate: this.epcRating.length
          ? { epcRating: this.epcRating, epcDocument: this.epcFile }
          : null,
        tenure: this.tenure,
        buyerId: null,
        county: this.county,
        createdBy: this.authUser.buyer_seller_id,
        floorPlan: this.floorPlan,
      };
      return await this.$store
        .dispatch(
          `${
            this.propertyDraft ? "updatePropertyDraft" : "createPropertyDraft"
          }`,
          this.propertyDraft
            ? {
                id: this.propertyDraft.id,
                data: { property: JSON.stringify(data) },
              }
            : { property: JSON.stringify(data) }
        )
        .then((res) => {
          this.switching = false;
          if (res == true) {
            return this.$store.dispatch("getPropertyDraft").then((res) => {
              this.showSpinner = false;
              if (res == true) {
                this.drafted = JSON.parse(this.propertyDraft.property);
                return true;
              } else {
                alert("error in fetching draft");
                return false;
              }
            });
          } else {
            this.showSpinner = false;
            alert("error in drafting");
            return false;
          }
        });
    },
    async submit() {
      this.showSpinner = true;
      this.delayCreate = true;
      const data = {
        sellerId: this.authUser.buyer_seller_id,
        addressType: this.addressType,
        description: this.convertedDescription,
        addressType: this.addressType,
        status: this.status,
        propertyType: this.type,
        price: parseInt(this.price.replace(/,/g, "")),
        houseNo: this.houseNo,
        street: this.street,
        city: this.city,
        postCode: this.postCode,
        country: this.country,
        noOfBedrooms: this.bedroom.length,
        noOfBathrooms: this.bathroom.length,
        noOfOtherRooms: this.otherroom.length,
        basement: this.basement ? "Yes" : "No",
        garage: this.garage,
        swimmingPool: this.swimmingPool,
        parking: this.parkingType,
        noOfCars: this.noOfCars,
        garden: this.garden,
        gardenArea: this.gardenArea,
        images: this.images,
        epcCertificate: {
          epcRating: this.epcRating,
          epcDocument: this.epcFile,
        },
        tenure: this.tenure,
        county: this.county,
        createdBy: this.authUser.buyer_seller_id,
        floorPlan: JSON.stringify(this.floorPlan),
      };
      await this.$store.dispatch("addProperty", data).then(async (res) => {
        this.delayCreate = false;
        if (res.success) {
          this.propertyId = res.data.id;
          this.stopFromCreatingDraft = true;
          await this.$store
            .dispatch("deletePropertyDraft", this.propertyDraft.id)
            .then((res) => {
              if (res == true) {
              } else {
                this.showSpinner = false;
              }
            });
          await this.$store.dispatch("getUserProperties");
          await this.$store
            .dispatch("getAllProperties", {
              currentPage: 1,
              perPage: 10,
              sortItem: "id",
              sortOrder: "desc",
              search: "",
            })
            .then((response) => {
              this.showSpinner = false;
              if (response == true) {
                this.$store.commit("setPropertyDraft", null);
                this.$store.commit("setCurrentDraft", null);
                this.$store.commit("setIndex", 0);
                // this.$router.push(`/property/package/${res.data.id}`);
                let arr = Array.from(
                  document.querySelectorAll(".wizard-nav li")
                ).filter((a) => a.querySelector("i").innerText !== "9");
                for (let i = 0; i < arr.length; i++) {
                  arr[i].style.pointerEvents = "none";
                }
                this.next();
              } else alert("error");
            });
        } else {
          this.showSpinner = false;
          alert("error");
        }
      });
    },
  },
  async created() {
    if (this.$route.query["redirect-from"] == "paypal") {
      if (this.$route.query["success"] == "false") {
        this.$nextTick(() => {
          this.$refs["paypal-failure-modal"].show();
        });
        return;
      }
      this.$store.commit("setShowLoader", true);
      let decryptedPackageId = await this.decrypt(this.$route.query.packageId);
      this.$store
        .dispatch(
          "changePackageStatus",
          !decryptedPackageId ? this.$route.query.packageId : decryptedPackageId
        )
        .then(async (res) => {
          if (res == true) {
            this.shouldShow = !this.shouldShow;
            let decryptedPropertyId = await this.decrypt(
              this.$route.query.propertyId
            );
            this.$store
              .dispatch(
                "publish",
                !decryptedPropertyId
                  ? this.$route.query.propertyId
                  : decryptedPropertyId
              )
              .then((res) => {
                this.$store.commit("setShowLoader", false);
                if (res == true) {
                  this.$store.dispatch("getUserProperties");
                } else {
                  alert("Error in publishing");
                }
              });
          } else {
            this.$store.commit("setShowLoader", false);

            alert("error in status change");
          }
        });
    }
  },
  async mounted() {
    if (this.$route.query.propertyId)
      this.$nextTick(() => {
        let arr = Array.from(
          document.querySelectorAll(".wizard-nav li")
        ).filter((a) => a.querySelector("i").innerText !== "9");
        for (let i = 0; i < arr.length; i++) {
          arr[i].style.pointerEvents = "none";
        }
        this.$refs.formWizard.changeTab(this.$refs.formWizard.currentIndex, 8);
      });
    else {
      document.querySelector("body").addEventListener("click", () => {
        if (!this.doNotDraft && this.focused) {
          this.focused = false;
        } else if (!this.focused && !this.doNotDraft) {
          this.convertObject();
          this.createDraft();
          this.doNotDraft = true;
        } else {
          this.focused = false;
          this.doNotDraft = true;
        }
      });
      if (this.propertyDraft) {
        this.drafted = JSON.parse(this.propertyDraft.property);
        this.houseNo = this.drafted.houseNo;
        this.county = this.drafted.county;
        this.city = this.drafted.city;
        this.postCode = _.get(this.drafted, "postCode", "").trim();
        this.street = this.drafted.street;
        this.price = this.formatter(this.drafted.price);
        this.type = this.drafted.propertyType;
        this.garage = this.drafted.garage;
        this.tenure = this.drafted.tenure;
        this.swimmingPool = this.drafted.swimmingPool;
        this.noOfCars = this.drafted.noOfCars;
        this.parkingType = this.drafted.parking;
        this.garden = this.drafted.garden;
        this.gardenArea = this.drafted.gardenArea;
        this.drafted.garden.length
          ? (this.isGardenChecked = true)
          : (this.isGardenChecked = false);
        this.epcRating = this.drafted.epcCertificate
          ? this.drafted.epcCertificate.epcRating
          : "";
        if (this.drafted.description.length) {
          this.$nextTick(() => {
            let found = this.drafted.description.filter(
              (desc) => desc.label == "bedroom"
            );
            let orderArr = Array.from(
              new Set(found.map((f) => parseInt(f.order)))
            ).map((order) => {
              return {
                order: order,
              };
            });
            this.addToDescription(
              "bedroom",
              this.drafted.noOfBedrooms,
              orderArr
            );
            this.bedroomDescription = [];
            for (let i = 0; i < found.length; i++) {
              this.bedroomDescription = [
                ...this.bedroomDescription,
                JSON.parse(`{ "${found[i].key}" : {
          "label": "${found[i].label}",
          "order": "${found[i].order}",
          "value": "${found[i].value[found[i].key].replace(/[\n\r]/g, "\\n")}",
          "key": "${found[i].key}"
        }
        }`),
              ];
            }
          });
          this.$nextTick(() => {
            let found = this.drafted.description.filter(
              (desc) => desc.label == "bathroom"
            );
            let orderArr = Array.from(
              new Set(found.map((f) => parseInt(f.order)))
            ).map((order) => {
              return {
                order: order,
              };
            });
            this.addToDescription(
              "bathroom",
              this.drafted.noOfBathrooms,
              orderArr
            );
            this.bathroomDescription = [];
            for (let i = 0; i < found.length; i++) {
              this.bathroomDescription = [
                ...this.bathroomDescription,
                JSON.parse(`{ "${found[i].key}" : {
          "label": "${found[i].label}",
          "order": "${found[i].order}",
          "value": "${found[i].value[found[i].key].replace(/[\n\r]/g, "\\n")}",
          "key": "${found[i].key}"
        }
        }`),
              ];
            }
          });
          this.$nextTick(() => {
            let found = this.drafted.description.filter(
              (desc) => desc.label == "otherroom"
            );
            let orderArr = Array.from(
              new Set(found.map((f) => parseInt(f.order)))
            ).map((order) => {
              return {
                order: order,
              };
            });
            this.addToDescription(
              "otherroom",
              this.drafted.noOfOtherRooms,
              orderArr
            );
            this.otherroomDescription = [];
            for (let i = 0; i < found.length; i++) {
              this.otherroomDescription = [
                ...this.otherroomDescription,
                JSON.parse(`{ "${found[i].key}" : {
          "label": "${found[i].label}",
          "order": "${found[i].order}",
          "value": "${found[i].value[found[i].key].replace(/[\n\r]/g, "\\n")}",
          "key": "${found[i].key}"
        }
        }`),
              ];
            }
          });
          this.$nextTick(() => {
            let found = this.drafted.description.filter(
              (desc) => desc.label == "custom"
            );
            this.customRoomDescription = [];
            for (let i = 0; i < found.length; i++) {
              this.customRooms = [...this.customRooms, { order: i + 1 }];
              this.customRoomDescription = [
                ...this.customRoomDescription,
                JSON.parse(`{ "${found[i].key}" : {
          "label": "${found[i].label}",
          "order": "${found[i].order}",
          "value": "${found[i].value[found[i].key].replace(/[\n\r]/g, "\\n")}",
          "key": "${found[i].key}"
        }
        }`),
              ];
            }
          });
          this.mainDescription = (() => {
            let found = this.drafted.description.find(
              (desc) => desc.label == "main"
            );
            return found.value[Object.keys(found.value)[0]];
          })();
          this.mainPageDescription = (() => {
            let found = this.drafted.description.find(
              (desc) => desc.label == "mainSecondary"
            );
            this.mainPageDescriptionTitle = Object.keys(found.value)[0];
            return found.value[Object.keys(found.value)[0]];
          })();
          this.garageDescription = (() => {
            let found = this.drafted.description.find(
              (desc) => desc.label == "garageDescription"
            );
            this.$set(
              this.removedList,
              "garageDescription",
              eval(found.removed)
            );
            this.garageDescriptionTitle = Object.keys(found.value)[0];
            return found.value[Object.keys(found.value)[0]];
          })();
          if (this.drafted.tenure == "lease") {
            this.tenureDescription = (() => {
              let found = this.drafted.description.find(
                (desc) => desc.label == "tenureDescription"
              );
              this.$set(
                this.removedList,
                "tenureDescription",
                eval(found.removed)
              );
              this.tenureDescriptionTitle = Object.keys(found.value)[0];
              return found.value[Object.keys(found.value)[0]];
            })();
          }
          this.swimmingPoolDescription = (() => {
            let found = this.drafted.description.find(
              (desc) => desc.label == "swimmingPoolDescription"
            );
            this.$set(
              this.removedList,
              "swimmingPoolDescription",
              eval(found.removed)
            );
            this.swimmingPoolDescriptionTitle = Object.keys(found.value)[0];
            return found.value[Object.keys(found.value)[0]];
          })();
          this.parkingDescription = (() => {
            let found = this.drafted.description.find(
              (desc) => desc.label == "parkingDescription"
            );
            this.$set(
              this.removedList,
              "parkingDescription",
              eval(found.removed)
            );
            this.parkingDescriptionTitle = Object.keys(found.value)[0];
            return found.value[Object.keys(found.value)[0]];
          })();
          this.gardenDescription = (() => {
            let found = this.drafted.description.find(
              (desc) => desc.label == "gardenDescription"
            );
            this.$set(
              this.removedList,
              "gardenDescription",
              eval(found.removed)
            );
            this.gardenDescriptionTitle = Object.keys(found.value)[0];
            return found.value[Object.keys(found.value)[0]];
          })();
        }
        this.isDescriptionChanged = false;
        this.images = this.drafted.images;
        if (this.startIndex == 6) this.imagesAdded = true;
        this.floorPlan = this.drafted.floorPlan ? this.drafted.floorPlan : [];
        this.epcFile = this.drafted.epcCertificate
          ? this.drafted.epcCertificate.epcDocument
            ? this.drafted.epcCertificate.epcDocument
            : []
          : [];
      }
      if (_.isEmpty(this.configurations)) {
        await this.$store.dispatch("getConfigurations");
      }
      this.termsAndConditions = this.configurations.find((config) => {
        return config.key == "termsAndConditions";
      });
      let article = document.createElement("article");
      let quill = new Quill(article, { readOny: true });
      quill.setContents(this.termsAndConditions.val);
      this.inner = quill.root.innerHTML;
      this.removeDiv();
      this.$nextTick(() => {
        let arr = document.querySelectorAll(".wizard-nav li i");
        let foundElement = Array.from(arr).find((a) => a.innerText == "7");
        foundElement.parentElement.parentElement.parentElement.addEventListener(
          "click",
          () => {
            for (let i = 0; i < 9; i++) {
              if (i !== 7 && i !== 8) this.$refs.formWizard.activateTab(i);
            }
            this.imagesAdded = true;
            this.$refs.formWizard.changeTab(
              this.$refs.formWizard.currentIndex,
              6
            );
          }
        );
      });
    }
    this.alignPayment();
    if (_.isEmpty(this.packages)) {
      await this.$store.dispatch("getAllPackages").then((res) => {
        if (res == true) {
          this.total = this.packages[0].price;
          this.grandTotal = this.total;
        } else alert("error in fetching packages");
      });
    } else {
      this.total = this.packages[0].price;
      this.grandTotal = this.total;
    }
    if (_.isEmpty(this.properties))
      await this.$store.dispatch("getUserProperties");
    let plainText = await this.decrypt(this.$route.query.propertyId);
    this.propertyId =
      (this.$route.query.propertyId
        ? plainText
        : this.$route.query.propertyId) || this.propertyId;
    this.property = this.properties.find((property) => {
      return property.id == this.propertyId;
    });
    if (Object.keys(this.buyerSeller).length) {
      this.$store
        .dispatch("getConfigurationsByKey", "referralSlabs")
        .then((res) => {
          if (res.data.success) {
            this.referralSlabs = res.data.data;
            this.showRedeem =
              this.buyerSeller.referralPoints >=
              this.referralSlabs.val.slab1.range;
          }
        });
    }
  },
};
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>
div >>> p {
  margin: 0;
  white-space: break-spaces;
}

.button-link {
  background: transparent;
  border: none;
  color: blue;
}

.payment-add-setinumber {
  text-align: -webkit-left !important;
  width: 100% !important;
  margin-left: 0px !important;
}

.font-set {
  font-family: "Lato", sans-serif !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  color: black;
  font-style: normal;
}

.lookup:hover {
  color: white !important;
}

div >>> .share-network-facebook {
  margin-right: 5px;
}

div >>> .share-network-twitter {
  margin-right: 5px;
}

div >>> .share-network-linkedin {
  margin-right: 5px;
}

div >>> .share-network-email {
  margin-right: 5px;
}

@media (min-width: 300px) and (max-width: 700px) {
  .w135 {
    width: 80px;
  }
  .res-ww-50 {
    width: 50% !important;
  }
  .res-ww-40 {
    width: 40% !important;
  }
  .res-padding-31 {
    padding-left: 3px !important;
    padding-right: 1px !important;
  }
  .res-ww-60 {
    width: 60% !important;
  }
  .res-parkking {
    text-align: left !important;
    padding: 10px !important;
  }

  div >>> input[name="cardnumber"] {
    width: 80% !important;
  }
  div >>> input[name="exp-date"] {
    width: 100% !important;
  }

  .res-float {
    padding-bottom: 37px !important;
  }
  .res-margin-l0 {
    margin-left: 0px !important;
    /* width: 130px !important; */
    width: 100% !important;
  }

  .float-res-right {
    float: right !important;
  }

  .res-set-span-payment {
    text-align: -webkit-left !important;
    width: 100% !important;
    margin-left: 0px !important;
  }

  .res-float {
    float: none !important;
    padding-bottom: 18px !important;
  }

  .res-photo-pad {
    padding-left: 15px !important;
    padding-right: 0px !important;
  }

  .res-display-sm-hide {
    display: none !important;
  }

  .res-remocinig-margin {
    margin: auto !important;
  }

  .w-55-coupon {
    width: 99% !important;
  }

  .res-pr-0 {
    padding-right: 0px !important;
  }

  div >>> .stripe-card {
    width: 100% !important;
  }

  .res-img-w {
    width: 22px !important;
    height: 22px !important;
  }
  .res-margin-imgaes {
    margin-bottom: 1.5rem !important;
  }
  .width-fix12 {
    width: 100% !important;
  }
  .res-pl-0 {
    padding-left: 0px !important;
  }
  .res-remove-paddings-multi {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .res-ml-54 {
    margin-left: 54px !important;
  }
  .res-mt-3 {
    margin-top: 1.5rem !important;
  }
  .res-mt-2 {
    margin-top: 1rem !important;
  }

  .res-pt-0 {
    padding-top: 0px !important;
  }

  .res-ml-0 {
    margin-left: 0px !important;
  }

  .res-next-button {
    color: white;
    width: 44% !important;

    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .pull-right {
    float: none !important;
    margin-left: 0px !important;
  }
  .res-back-button {
    width: 44% !important;
    margin-right: 17px !important;
  }
  .res-move-mt {
    margin-top: 0px !important;
  }
  .res-sm-img {
    width: 22px !important;
    height: 22px !important;
  }

  div >>> .wizard-progress-with-circle {
    display: none !important;
  }

  div >>> .wizard-nav-pills {
    display: none !important;
  }
  .setleft {
    padding-left: 26px !important;
  }
  .priceicon {
    position: absolute;
    display: block;
    transform: translate(0, -50%);
    top: 54% !important;
    pointer-events: none;
    width: 25px;
    text-align: center;
    font-style: normal;
  }
  .paddingset {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  div >>> .h0 {
    height: 300px !important;
  }

  .premove {
    margin: 0 !important;
    padding: 0 !important;
  }

  div >>> .h154 {
    height: 220px !important;
  }

  .d-noneneo {
    display: none !important;
  }
}

.px-6 {
  padding-right: 4rem;
  padding-left: 4rem;
}
div >>> .vue-form-wizard * {
  font-style: normal;
}

.px-6 {
  padding-right: 4rem;
  padding-left: 4rem;
}

div >>> .wizard-icon {
  /* width: 69px; */
  width: 71px;
}
div >>> .vue-form-wizard.md .wizard-icon-circle {
  outline: none !important;
  width: 73px !important;
  height: 73px !important;
  font-size: 24px;
}

div >>> #modal-1___BV_modal_content_ {
  background: transparent;
  border: none;
}

div >>> #modal-1___BV_modal_body_ {
  display: flex;
  justify-content: center;
}

div >>> .wizard-icon-container {
  border-radius: 50% !important;
  top: 13px;
  position: relative;
  background-color: rgb(84, 143, 77);
  left: 0px;
  color: back;
  /* height: 70px; */
  height: 73px;
  top: -2px;
  border-radius: 51% !important;
}
div >>> .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: grey;
}
.input-icon {
  position: relative;
}

.pakring-set {
  height: 50px;
  border: 1px solid rgb(221, 221, 221) !important;
  text-align: center;
  border-radius: 0 !important;
  margin-right: 13px;
  margin-left: 4px;
}

.input-icon > i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 69%;
  pointer-events: none;
  width: 25px;
  text-align: center;
  font-style: normal;
}

.input-icon > input {
  padding-left: 25px;
  padding-right: 0;
}

.input-icon-right > i {
  right: 0;
}

.input-icon-right > input {
  padding-left: 0;
  padding-right: 25px;
  text-align: right;
}

.color-black {
  color: black !important;
}

@media (min-width: 432px) and (max-width: 4146px) {
  .span-inline-box {
    width: 300px !important;
  }
  .res-disply-big-hide {
    display: none !important;
  }

  .res-hide-on-big {
    display: none !important;
  }
}

@media (min-width: 300px) and (max-width: 700px) {
  .w-30 {
    width: 50% !important;
    margin-bottom: 0px !important;
  }

  .respadiing {
    padding: 0px !important;
  }
  div >>> .wizard-tab-content {
    padding-top: 0px !important;
  }
  .res-display-sm-hide {
    display: none !important;
  }
  .res-w-1oo {
    width: 100% !important;
  }

  .w-40 {
    width: 55% !important;
    margin-bottom: 0px !important;
  }
  .m-set {
    margin-left: 10px !important;
    margin-top: 1px !important;
  }
  .paymentstyle {
    margin-left: 40px !important;
    width: 80% !important;
  }

  .buttonpay {
    margin-left: 100px !important;
  }

  .res-d-none {
    display: none !important;
  }

  div >>> .stripe-card {
    width: 100% !important;
    border: 1px solid grey;
    border-radius: 1px;
    padding: 5px;
  }
}

@media (min-width: 400px) and (max-width: 700px) {
  .buttonpay {
    margin-left: 150px !important;
  }
  .paymentstyle {
    margin-left: 53px !important;
    width: 80% !important;
  }

  .for-res button {
    width: 116px;
  }
  .responsive-line {
    width: 130px;
    margin-left: 50px;
  }
  .listing-button-color {
    margin-left: 0px !important;
  }
  .for-inline-button {
    justify-content: center;
  }
  .input-icon {
    margin-bottom: 6px !important;
  }
  .dropdown {
    margin-bottom: 15px !important;
  }
}

.center {
  background-color: red;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}

.scrolls {
  height: 635px;
  overflow: auto;
}

.error {
  color: red;
}

.fa-check {
  color: green;
}

.hover-disabled {
  cursor: not-allowed;
}

div >>> .stripe-card {
  width: 100%;
  border: 1px solid grey;
  border-radius: 1px;
  padding: 5px;
}

.btn-outline-info2 {
  color: #fff;
  border-color: #272b32;
  background-color: #272b32;
}

.btn-outline-info {
  color: #272b32;
  border-color: #272b32;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #272b32;
  border-color: #272b32;
}

.packs {
  color: white;
  height: auto;
}
.stripe-card {
  width: 300px;
  border: 1px solid grey;
}
.stripe-card.complete {
  border-color: green;
}
.w-30 {
  width: 30%;
}
.paymentstyle {
  margin-top: 5px;
}
.buttonpay {
  background: rgb(84, 143, 77);
  width: 132px;
  color: white;
  border-color: rgb(84, 143, 77);
}

.span-color {
  color: rgb(84, 143, 77);
  font-weight: bold;
  font-size: 16px;
}
.pre-listing {
  font-size: 16px;
  font-weight: 500;
  margin: 0px !important;
}
.btn-payment {
  color: white;
  float: right;
  background: #548f4d !important;
}
.btn-paynow {
  color: white;
  background: #548f4d !important;
  padding: 18px;
}

.addButtonPro {
  padding-right: 30px;
  padding-left: 30px;
  height: 50px;
  padding-top: 5px;
}
.popover {
  max-width: 443px;
}

@media (max-width: 430px) {
  .for-res {
    /* width: 111px; */
    width: 100%;
  }
  .for-res button {
    /* width: 130px !important; */
    width: 100% !important;
  }
  .for-res-left {
    width: 100px !important;
    margin-left: 46px !important;
  }
  .responsive-line {
    width: 130px !important;
    margin-left: 50px !important;
  }
  .listing-button-color {
    margin-left: 0px !important;
  }
  .for-inline-button {
    justify-content: center !important;
  }
  .input-icon {
    margin-bottom: 6px !important;
  }
  .dropdown {
    margin-bottom: 15px !important;
  }
  .user-page.section-padding select[data-v-a2d10c0c] {
    padding: 0px 0px;
  }
  .for-responsive-width {
    margin-top: 14px !important;
    width: 100px !important;
  }
  .for-inline {
    flex-direction: column !important;
    float: left !important;
  }
  .for-the-line {
    flex-direction: column;
  }
  .txt-style {
    text-align: justify !important;
  }
  .text-style-for-mob {
    text-align: justify !important;
    width: 100% !important;
  }
  .width-fix {
    /* width: 250px; */
    margin-right: 10px !important;
  }
  .yellew-text {
    width: 100% !important;
  }
  .res-yellew-text {
    margin-left: -0px !important;
  }
}

div >>> .popover-body {
  background: #69ccf3;
  color: white;
}

div >>> .bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #69ccf3 !important;
}

@media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
  .onlysafari {
    padding-left: 10px !important;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .onlysafari {
      padding-left: 10px !important;
    }
  }
}

/* Safari 10.1 */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
    .onlysafari {
      padding-left: 10px !important;
    }
  }
}

/* Safari 6.1-10.0 (but not 10.1) */
@media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
  .onlysafari {
    padding-left: 10px !important;
  }
}
</style>
